import React from 'react';
import { Form } from 'react-bootstrap';

import dayjs from 'dayjs';

export default function LicenseDatePicker(props) {
  const { type, date, status, handleChange } = props;

  const license_name = {
    urjanet: 'Urjanet',
    plug: 'Plug',
    analysis: 'Analysis',
    ghg: 'GHG',
    solar: 'Solar',
  };
  const dateColor = status === 'expired' ? 'red' : '#212529';

  return (
    <Form.Group>
      <Form.Label>{license_name[type] + ' License'}</Form.Label>
      <Form.Control
        name={'expiration_date-' + type}
        aria-label={'expirationDate-' + type}
        type='date'
        style={{ color: dateColor }}
        defaultValue={date ? dayjs.utc(date).format('YYYY-MM-DD') : ''}
        onChange={(e) => {
          handleChange(type, e.target.value);
        }}
      />
    </Form.Group>
  );
}
