import React from 'react';

export const DEFAULT_PORTFOLIO_VALUES = {
  id: '',
  name: '',
  organizations: [],
};

const tableLinkStyle = {
  margin: '0px',
  padding: '0px',
};

// row.organizations is an array of organizations for the org
export function portfolioOrganizationFormatter(
  row,
  setActiveOrganization,
  setActivePortfolio,
  withConfirm,
  history,
  formatExtraData
) {
  return (
    <>
      {row.organizations.length === 0 && <div>No Organizations</div>}
      {row.organizations.length === 1 &&
        portfolioOrganizationLink(
          row,
          row.organizations[0],
          formatExtraData,
          setActiveOrganization,
          setActivePortfolio,
          withConfirm,
          history
        )}

      {row.organizations.length > 1 && (
        <details
          open={formatExtraData.organizationFilterValue === '' ? false : true}
        >
          <summary className='et-custom-summary'>
            {row.organizations.length + ' Organizations'}
          </summary>
          {row.organizations.map((organization) => (
            <div key={'organization_' + organization.id}>
              {organization.name
                .toLowerCase()
                .includes(formatExtraData.organizationFilterValue) &&
                portfolioOrganizationLink(
                  row,
                  organization,
                  formatExtraData,
                  setActiveOrganization,
                  setActivePortfolio,
                  withConfirm,
                  history
                )}
            </div>
          ))}
        </details>
      )}
    </>
  );
}

export function portfolioOrganizationLink(
  row,
  organization,
  formatExtraData,
  setActiveOrganization,
  setActivePortfolio,
  withConfirm,
  history
) {
  return (
    <div
      className='et-link'
      style={tableLinkStyle}
      onClick={() => {
        if (
          formatExtraData.userSelectedPortfolio.id !== row.id &&
          formatExtraData.userSelectedPortfolio.id !== -1
        ) {
          withConfirm(
            `Selecting this organization will switch your active portfolio to ${row.name}. Do you wish to proceed?`,
            () => {
              setActivePortfolio({
                id: parseInt(row.id),
                name: row.id !== -1 ? row.name : '',
              });
              setActiveOrganization({
                id: parseInt(organization.id),
                name: organization.name,
              });
              history.push('/resources');
            }
          );
        } else {
          setActiveOrganization({
            id: parseInt(organization.id),
            name: organization.name,
          });
          history.push('/resources');
        }
      }}
    >
      {organization.name}
    </div>
  );
}
