import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import LicenseReportRecipientModal from '../../components/modals/LicenseReportRecipientModal';
import { ApiServiceServerless } from '../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../components/modals/ConfirmationModal';
import EnergyTracerTable from '../../components/tables';

function LicenseReportRecipients(props) {
  const { showToast } = props;

  const [showModal, setShowModal] = useState(false);
  const [recipients, setRecipients] = useState([]);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isSaving, setIsSaving] = useState(false);

  const COLUMNS = [
    {
      dataField: 'name',
      text: 'Name',
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'actions',
      formatter: (_cell, row) => (
        <Button
          variant='danger'
          onClick={(e) => {
            handleDeleteRecipient(row);
          }}
        >
          Delete
        </Button>
      ),
      text: 'Actions',
    },
  ];

  const getRecipients = useCallback(() => {
    ApiServiceServerless.get(`/licenses/license_report/recipient`)
      .then((res) => {
        setRecipients(res.data);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  }, [showToast]);

  useEffect(() => {
    getRecipients();
  }, [getRecipients]);

  const saveRecipient = (name, email) => {
    setIsSaving(true);
    ApiServiceServerless.post(`/licenses/license_report/recipient`, {
      name: name,
      email: email,
    })
      .then(() => {
        showToast('success', 'Success', 'Recipient added successfully.');
        getRecipients();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setShowModal(false);
        setIsSaving(false);
      });
  };

  const handleDeleteRecipient = (recipient) => {
    withConfirm('Are you sure you want to delete this recpient?', () => {
      deleteRecipient(recipient);
    });
  };

  const deleteRecipient = (recipient) => {
    ApiServiceServerless.delete(
      `/licenses/license_report/recipient/${recipient.id}`
    )
      .then(() => {
        getRecipients();
        showToast('success', 'Success', 'Recipient deleted successfully.');
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  return (
    <>
      <ConfirmationModal {...confirmationModalProps} />
      <Button onClick={() => setShowModal(true)}>
        {' '}
        {'Add Report Recipient'}
      </Button>
      <div style={{ marginTop: '20px' }}>
        <EnergyTracerTable data={recipients} columns={COLUMNS} keyField='id' />
      </div>
      <LicenseReportRecipientModal
        show={showModal}
        onHide={() => setShowModal(false)}
        saveRecipient={saveRecipient}
        isSaving={isSaving}
      />
    </>
  );
}

export default LicenseReportRecipients;
