export const UTILITY_TYPES = {
  electric: 'Electric',
  gas: 'Gas',
  water: 'Water',
  propane: 'Propane',
};

export const EMISSION_SCOPES = {
  electric: 'indirect',
  gas: 'direct',
  propane: 'direct',
};

export const addAccountTypes = [
  'electric',
  'gas',
  'propane',
  'water',
  'production',
];
