import React, { useState, useEffect } from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

import AddEntityActionButtons from '../forms/AddEntityActionButtons';
import parseNumber from '../../helpers/parseNumber';
import { MODEL_LENGTH_LOOKUP } from '../analysis/regressors';

export default function RegressionOptionsModal(props) {
  const {
    show,
    onHide,
    defaultRegressionOptions,
    loadedRegressionOptions,
    setRegressionOptions,
    regressionPieces,
  } = props;

  const [localOptions, setLocalOptions] = useState(defaultRegressionOptions);

  const [showForcedBounds, setShowForcedBounds] = useState(false);
  const [localForcedBounds, setLocalForcedBounds] = useState({});

  const [localBoundKeyTrigger, setLocalBoundKeyTrigger] = useState(0);
  const [localBoundKey, setLocalBoundKey] = useState(0);

  //if defaults is none set to length, else use defaults
  const handleResetBounds = (local_pieces, defaults) => {
    let forced_bounds_obj = {};
    if (!defaults) {
      let length = 1;
      local_pieces.forEach((piece) => {
        if (Object.keys(piece).includes('model')) {
          length += MODEL_LENGTH_LOOKUP[piece['model']];
        }
      });
      for (let i = 0; i < length; i++) {
        forced_bounds_obj[i] = null;
      }
      setShowForcedBounds(false);
    } else {
      defaults.forEach((val, idx) => {
        forced_bounds_obj[idx] = val;
      });
    }
    setLocalForcedBounds(forced_bounds_obj);
    setLocalBoundKeyTrigger(0);
  };

  useEffect(() => {
    handleResetBounds(regressionPieces, null);
  }, [regressionPieces]);

  const handleBoundChange = (idx, val) => {
    setLocalForcedBounds((prev) => ({
      ...prev,
      [idx]: val,
    }));
  };

  const handleBoundClear = (idx) => {
    setLocalForcedBounds((prev) => ({
      ...prev,
      [idx]: null,
    }));
    setLocalBoundKeyTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    setLocalBoundKey((prev) => prev + 1);
  }, [localBoundKeyTrigger]);

  useEffect(() => {
    let local_bounds = null;
    let all_null = true;

    if (Object.keys(localForcedBounds).length > 0) {
      local_bounds = [];
      Object.values(localForcedBounds).forEach((bound) => {
        local_bounds.push(bound);
        all_null = all_null && bound === null;
      }, []);
    }

    setLocalOptions((prev) => ({
      ...prev,
      forced_bounds: local_bounds,
    }));
    if (local_bounds === null || all_null) {
      setRegressionOptions((prev) => ({
        ...prev,
        forced_bounds: null,
      }));
    }
  }, [localForcedBounds, setRegressionOptions]);

  useEffect(() => {
    if (loadedRegressionOptions !== null) {
      setLocalOptions(loadedRegressionOptions);
      let loaded_bounds = loadedRegressionOptions['forced_bounds'];
      if (loaded_bounds) {
        setShowForcedBounds(true);
        handleResetBounds(0, loaded_bounds);
      }
    } else {
      setLocalOptions(defaultRegressionOptions);
    }
  }, [loadedRegressionOptions, defaultRegressionOptions]);

  const saveOptions = () => {
    setRegressionOptions(localOptions);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton style={{ height: '50px' }}>
        Regression Options
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Outlier Detection</Form.Label>
          <Form.Control
            as='select'
            required
            onChange={(e) =>
              setLocalOptions({
                ...localOptions,
                outlier: e.target.value === 'true' ? true : false,
              })
            }
            value={localOptions.outlier}
          >
            <option key={`type-opt-on`} value={true}>
              On
            </option>
            <option key={`type-opt-off`} value={false}>
              Off
            </option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Set Forced Bounds</Form.Label>
          <Form.Control
            as='select'
            required
            onChange={(e) => {
              handleResetBounds(regressionPieces, null);
              setShowForcedBounds(e.target.value === 'true' ? true : false);
            }}
            value={showForcedBounds}
          >
            <option key={`type-opt-on`} value={true}>
              On
            </option>
            <option key={`type-opt-off`} value={false}>
              Off
            </option>
          </Form.Control>
        </Form.Group>
        {showForcedBounds && localOptions['forced_bounds'] && (
          <Form.Group>
            <Form.Label>Forced Bounds</Form.Label>
            <Form.Row>
              {localOptions['forced_bounds'].map((cell, idx) => (
                <Form.Group as={Col} md={6} key={idx}>
                  <Form.Label>{'x' + idx}</Form.Label>
                  <NumberFormat
                    customInput={Form.Control}
                    thousandSeparator
                    isNumericString
                    decimalScale={2}
                    fixedDecimalScale
                    defaultValue={cell}
                    key={localBoundKey}
                    onChange={(e) =>
                      handleBoundChange(idx, parseNumber(e.target.value))
                    }
                  />
                  {localOptions['forced_bounds'][idx] !== null && (
                    <Button
                      variant='outline-danger'
                      style={{
                        position: 'relative',
                        left: '85%',
                        top: '-34%',
                        padding: '0px',
                        borderWidth: '0px',
                        borderRadius: '50%',
                        height: '28px',
                        width: '28px',
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: '18px', lineHeight: 'inherit' }}
                        icon={faTimesCircle}
                        onClick={() => handleBoundClear(idx)}
                      />
                    </Button>
                  )}
                </Form.Group>
              ))}
            </Form.Row>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AddEntityActionButtons onCancel={onHide} onSubmit={saveOptions} />
      </Modal.Footer>
    </Modal>
  );
}
