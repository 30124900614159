import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import Helmet from 'react-helmet';
import dayjs from 'dayjs';

import { ApiServiceServerless } from '../xhr_libs';
import { useTabUrl } from '../helpers/tracked';
import { useOrganizationRoles } from '../helpers/checkRoles';
import Projects from '../components/analysis/Projects';

import Loader from '../components/Loader';
import GHGConversionsMain from '../components/analysis/Resources/GHGConversions/';
import SavingMeasures from '../components/analysis/Resources/SavingMeasures';
import RegressionAnalysis from '../components/analysis/RegressionAnalysis';
import CustomRates from '../components/analysis/Resources/CustomRates';
import RegressionEventCollections from '../components/analysis/Resources/RegressionEvents/RegressionEventCollections';
import NavigationAlert from '../components/analysis/NavigationAlert';

const PAGE_TITLE = 'Analysis';

export default function Analysis(props) {
  const {
    showToast,
    userSelectedOrganization,
    userSelectedOrganizationDetails,
  } = props;

  const [organizationId, setOrganizationId] = useState(null);
  const [activeAnalysisLicense, setActiveAnalysisLicense] = useState(false);
  const [activeGHGLicense, setActiveGHGLicense] = useState(false);

  useEffect(() => {
    let details = { ...userSelectedOrganizationDetails };
    if (details.id) {
      setOrganizationId(details.id);
      setActiveAnalysisLicense(
        details &&
          details.licenses &&
          details.licenses.analysis &&
          details.licenses.analysis.status === 'active'
      );
      setActiveGHGLicense(
        details &&
          details.licenses &&
          details.licenses.ghg &&
          details.licenses.ghg.status === 'active'
      );
    }
  }, [userSelectedOrganizationDetails]);

  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useTabUrl('projects');
  const [activeTabList, setActiveTabList] = useState([
    'regression-analysis',
    'projects',
  ]);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  useEffect(() => {
    addNewActiveTab(activeTab);
  }, [activeTab, addNewActiveTab]);

  const [organization, setOrganization] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [buildingsLookup, setBuildingsLookup] = useState({});
  const [buildingIds, setBuildingIds] = useState([]);
  const [defaultPerformanceProject, setDefaultPerformanceProject] =
    useState(null);
  const [existingModels, setExistingModels] = useState([]);
  const [plainModels, setPlainModels] = useState([]);
  const [availableUtilities, setAvailableUtilities] = useState([]);
  const [projects, setProjects] = useState([]);
  const [rates, setRates] = useState([]);
  const [rateCollections, setRateCollections] = useState([]);
  const [performanceMeasures, setPerformanceMeasures] = useState([]);
  const [GHGMeasures, setGHGMeasures] = useState([]);

  const [GHGConversions, setGHGConversions] = useState([]);
  const [GHGCollections, setGHGCollections] = useState([]);

  const [regressionEventCollections, setRegressionEventCollections] = useState(
    []
  );
  const [regressionEventYears, setRegressionEventYears] = useState([]);
  const [
    regressionEventCollectionsEditted,
    setRegressionEventCollectionsEditted,
  ] = useState(0);

  useEffect(() => {
    setOrganization('');
    setBuildingIds([]);
    setBuildingsLookup({});
    setBuildingIds([]);
    setDefaultPerformanceProject(null);
    setExistingModels([]);
    setPlainModels([]);
    setAvailableUtilities([]);
    setProjects([]);
    setRates([]);
    setRateCollections([]);
    setPerformanceMeasures([]);
    setGHGMeasures([]);
    setGHGCollections([]);
    setGHGConversions([]);
    setRegressionEventCollections([]);
    setRegressionEventYears([]);
  }, [userSelectedOrganization]);

  const createAccessAnalysis = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'analysis',
    'create'
  );
  const updateAccessAnalysis = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'analysis',
    'update'
  );
  const deleteAccessAnalysis = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'analysis',
    'delete'
  );

  const createAccessGHG = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'analysis',
    'create'
  );
  const updateAccessGHG = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'analysis',
    'update'
  );
  const deleteAccessGHG = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'analysis',
    'delete'
  );

  useEffect(() => {
    if (organizationId && activeAnalysisLicense) {
      setIsLoading(true);
      ApiServiceServerless.get(`/analysis/organization/${organizationId}`, {
        authorization_id: organizationId,
      })
        .then((res) => {
          setOrganization(res.data['organization']);
          setBuildings(res.data['buildings']);
          let building_lookup = {};
          res.data['buildings'].forEach((building) => {
            building_lookup[building.id] = building;
          });
          setBuildingsLookup(building_lookup);
          setBuildingIds(
            Object.keys(building_lookup).map((building) => parseInt(building))
          );
          setPlainModels(res.data['regressions']);
          setAvailableUtilities(res.data['utility_types']);
          setRates(res.data['rates']);
          setRateCollections(res.data['rate_collections']);
          setPerformanceMeasures(res.data['operational_savings']);
          setGHGMeasures(res.data['ghg_savings']);
          setGHGCollections(res.data['ghg_collections']);
          setGHGConversions(res.data['ghg_conversions']);
          setRegressionEventCollections(
            res.data['regression_event_collections']
          );
          setRegressionEventYears(res.data['regression_event_years']);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast('danger', 'Error', err);
          setIsLoading(false);
          throw err;
        });
    } else {
      setIsLoading(false);
      setOrganization('');
      setBuildings([]);
      setBuildingsLookup({});
      setBuildingIds([]);
      setPlainModels([]);
      setAvailableUtilities([]);
      setRates([]);
      setRateCollections([]);
      setPerformanceMeasures([]);
      setGHGCollections([]);
      setGHGConversions([]);
      setRegressionEventCollections([]);
      setRegressionEventYears([]);
    }
  }, [organizationId, activeAnalysisLicense, showToast]);

  useEffect(() => {
    let temp_projects = [];
    if (organization && organization.ghg_projects) {
      temp_projects = temp_projects.concat(
        Object.values(organization.ghg_projects)
      );
    }

    if (organization && organization.performance_projects) {
      temp_projects = temp_projects.concat(
        Object.values(organization.performance_projects)
      );
    }

    setProjects(temp_projects);
  }, [organization]);

  useEffect(() => {
    if (Object.keys(buildingsLookup).length > 0) {
      let new_models = plainModels.map((model) => {
        model.date_range =
          dayjs.utc(model.start_date).format('MMM YYYY') +
          ' - ' +
          dayjs.utc(model.end_date).format('MMM YYYY');
        model['building_name'] = buildingsLookup[model['building_id']]['name'];
        return model;
      });
      setExistingModels(new_models);
    } else {
      setExistingModels([]);
    }
  }, [plainModels, buildingsLookup]);

  const setLookup = {
    models: setPlainModels,
    rates: setRates,
    rate_collections: setRateCollections,
    performance_measures: setPerformanceMeasures,
    ghg_measures: setGHGMeasures,
    ghg_collections: setGHGCollections,
    ghg_conversions: setGHGConversions,
    regression_event_collections: setRegressionEventCollections,
  };

  const addToList = (data, resource) => {
    setLookup[resource]((prev) => [...prev, data]);
  };

  const updateList = (data, resource) => {
    setLookup[resource]((prev) =>
      prev.map((resource) => {
        if (resource['id'] !== data['id']) return resource;
        return data;
      })
    );
  };

  const removeFromList = (id, resource) => {
    setLookup[resource]((prev) =>
      prev.filter((resource) => resource.id !== id)
    );
  };

  return (
    <Container className='et-container et-main-tab-content' page='analysis'>
      <Helmet>
        <title>{PAGE_TITLE}</title>
      </Helmet>
      {!userSelectedOrganization.id && (
        <NavigationAlert
          mainText={'It looks like you do not have any organizations yet.'}
          buttonText={'New Organization'}
          location={'/portfolios'}
        />
      )}

      {userSelectedOrganization.id && !activeAnalysisLicense && (
        <div style={{ color: 'red' }}>
          This organization does not have a valid license to access this
          feature.Please contact an administrator about licensing.
        </div>
      )}
      {userSelectedOrganization.id && (
        <Tabs
          id='main-analysis-tabs'
          activeKey={activeTab}
          onSelect={(e) => addNewActiveTab(e)}
          className='et-main-tabs'
        >
          <Tab eventKey='projects' title={'Projects'}>
            {isLoading && <Loader />}
            {activeTabList.includes('projects') && !isLoading && (
              <Projects
                showToast={showToast}
                organization={organization}
                projects={projects}
                setProjects={setProjects}
                buildings={buildings}
                buildingIds={buildingIds}
                buildingsLookup={buildingsLookup}
                availableUtilities={availableUtilities}
                existingModels={existingModels}
                rates={rates}
                rateCollections={rateCollections}
                performanceMeasures={performanceMeasures}
                GHGMeasures={GHGMeasures}
                GHGConversions={GHGConversions}
                GHGCollections={GHGCollections}
                addToList={addToList}
                updateList={updateList}
                setDefaultPerformanceProject={setDefaultPerformanceProject}
                activeGHGLicense={activeGHGLicense}
                createAccessAnalysis={
                  createAccessAnalysis && activeAnalysisLicense
                }
                updateAccessAnalysis={
                  updateAccessAnalysis && activeAnalysisLicense
                }
                deleteAccessAnalysis={
                  deleteAccessAnalysis && activeAnalysisLicense
                }
                createAccessGHG={createAccessGHG && activeGHGLicense}
                updateAccessGHG={updateAccessGHG && activeGHGLicense}
                deleteAccessGHG={deleteAccessAnalysis && activeGHGLicense}
                open={activeTab === 'projects'}
              />
            )}
          </Tab>
          <Tab eventKey='regression-analysis' title={'Regressions'}>
            {isLoading && <Loader />}
            {activeTabList.includes('regression-analysis') && !isLoading && (
              <RegressionAnalysis
                showToast={showToast}
                organization={organization}
                buildings={buildings}
                availableUtilities={availableUtilities}
                createAccessAnalysis={createAccessAnalysis}
                defaultPerformanceProject={defaultPerformanceProject}
                existingModels={existingModels}
                addToList={addToList}
                updateList={updateList}
                removeFromList={removeFromList}
                projects={projects}
                updateAccessAnalysis={updateAccessAnalysis}
                deleteAccessAnalysis={deleteAccessAnalysis}
                regressionEventCollections={regressionEventCollections}
                regressionEventCollectionsEditted={
                  regressionEventCollectionsEditted
                }
                open={activeTab === 'regression-analysis'}
              />
            )}
          </Tab>
          <Tab eventKey='regression-events' title={'Regression Events'}>
            {isLoading && <Loader />}
            {activeTabList.includes('regression-events') && !isLoading && (
              <>
                <RegressionEventCollections
                  organization_id={organization.id}
                  createAccessAnalysis={createAccessAnalysis}
                  regressionEventCollections={regressionEventCollections}
                  regressionEventYears={regressionEventYears}
                  addToList={addToList}
                  updateList={updateList}
                  removeFromList={removeFromList}
                  showToast={showToast}
                  updateAccess={updateAccessAnalysis}
                  deleteAccess={deleteAccessAnalysis}
                  buildingsLookup={buildingsLookup}
                  existingModels={existingModels}
                  setRegressionEventCollectionsEditted={
                    setRegressionEventCollectionsEditted
                  }
                  open={activeTab === 'regression-events'}
                />
              </>
            )}
          </Tab>

          <Tab eventKey={'rates'} title='Rates'>
            {isLoading && <Loader />}
            {activeTabList.includes('rates') && !isLoading && (
              <CustomRates
                organizationId={organization.id}
                rates={rates}
                rateCollections={rateCollections}
                addToList={addToList}
                updateList={updateList}
                removeFromList={removeFromList}
                projects={projects}
                showToast={showToast}
                createAccess={createAccessAnalysis}
                updateAccess={updateAccessAnalysis}
                deleteAccess={deleteAccessAnalysis}
                open={activeTab === 'rates'}
              />
            )}
          </Tab>
          <Tab eventKey='ghg' title='Emissions'>
            {isLoading && <Loader />}
            {activeTabList.includes('ghg') && !isLoading && (
              <GHGConversionsMain
                showToast={showToast}
                organization={organization}
                createAccess={createAccessGHG}
                updateAccess={updateAccessGHG}
                deleteAccess={deleteAccessGHG}
                GHGCollections={GHGCollections}
                GHGConversions={GHGConversions}
                addToList={addToList}
                updateList={updateList}
                removeFromList={removeFromList}
                open={activeTab === 'ghg'}
              />
            )}
          </Tab>
          <Tab eventKey='saving-measures' title='Savings Measures'>
            {isLoading && <Loader />}
            {activeTabList.includes('saving-measures') && !isLoading && (
              <SavingMeasures
                organizationId={organization.id}
                projects={projects}
                performanceMeasures={performanceMeasures}
                GHGMeasures={GHGMeasures}
                addToList={addToList}
                updateList={updateList}
                removeFromList={removeFromList}
                showToast={showToast}
                createAccessAnalysis={createAccessAnalysis}
                updateAccessAnalysis={updateAccessAnalysis}
                deleteAccessAnalysis={deleteAccessAnalysis}
                createAccessGHG={createAccessGHG}
                updateAccessGHG={updateAccessGHG}
                deleteAccessGHG={deleteAccessGHG}
                open={activeTab === 'saving-measures'}
              />
            )}
          </Tab>
        </Tabs>
      )}
    </Container>
  );
}
