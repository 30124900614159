import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';
import findElementPath, { getBillData } from './AdminTestHelpers';

export default function AdminTestZone() {
  const [render, setRender] = useState('');
  const [option, setOption] = useState('0');
  const [selectionType, setSelectionType] = useState('1');
  const [formatComplete, setFormatComplete] = useState(0);
  const [getData, setGetData] = useState(0);

  const setSelectedNode = useCallback(
    (target) => {
      let old_selected = document.querySelector(
        '.et-pdf-selected-el[selection="' + selectionType + '"]'
      );
      if (old_selected) {
        old_selected.classList.remove('et-pdf-selected-el');
        old_selected.removeAttribute('selection');
        old_selected.parentElement.classList.remove('et-pdf-selected-parent');
      }
      target.classList.add('et-pdf-selected-el');
      target.setAttribute('selection', selectionType);
      target.parentElement.classList.add('et-pdf-selected-parent');

      findElementPath();
    },
    [selectionType]
  );

  const addClickTriggers = useCallback(() => {
    let contentEls = document.querySelectorAll('.et-pdf-content-el');
    contentEls.forEach((el) => {
      el.onclick = function (e) {
        setSelectedNode(e.target);
      };
    });
  }, [setSelectedNode]);

  useEffect(() => {
    addClickTriggers();
  }, [formatComplete, addClickTriggers]);

  const breakUpSpans = useCallback((withBRs) => {
    let spans = document.querySelectorAll('.et-pdf-render div span');
    spans.forEach((span) => {
      let texts = span.innerHTML.split('<br>');
      span.innerHTML = texts[0];
      span.classList.add('et-pdf-content-el');

      if (withBRs) {
        span.parentElement.appendChild(document.createElement('br'));
      }
      texts = texts.slice(1, texts.length);
      texts.forEach((text) => {
        if (text !== '') {
          let new_span = span.cloneNode();
          new_span.innerHTML = text;

          span.parentElement.appendChild(new_span);
          if (withBRs) {
            span.parentElement.appendChild(document.createElement('br'));
          }
        }
      });
    });
  }, []);

  const insertDivs = useCallback((withBRs) => {
    let spans = document.querySelectorAll('.et-pdf-render div span');
    spans.forEach((span) => {
      let texts = span.innerHTML.split('<br>');
      span.innerHTML = '';
      texts.forEach((text) => {
        if (text !== '') {
          let new_div = document.createElement('div');
          new_div.innerHTML = text;
          new_div.classList.add('et-pdf-content-el');
          span.appendChild(new_div);
          if (withBRs) {
            span.appendChild(document.createElement('br'));
          }
        }
      });
    });
  }, []);

  const getPDF = useCallback(() => {
    setRender('');
    ApiServiceServerless.get(`/admin/admin_test`).then((res) => {
      let html = res.data;
      setRender(html);
    });
  }, []);

  useEffect(() => {
    getPDF();
  }, [option, getPDF]);

  const refreshData = () => {
    getPDF();
  };

  const formatPDF = useCallback(() => {
    if (option === '1') {
      breakUpSpans(false);
    } else if (option === '2') {
      breakUpSpans(true);
    } else if (option === '3') {
      insertDivs(false);
    } else if (option === '4') {
      insertDivs(true);
    }
    setFormatComplete((prev) => prev + 1);
  }, [option, insertDivs, breakUpSpans]);

  useEffect(() => {
    formatPDF();
  }, [render, formatPDF]);

  useEffect(() => {
    if (getData !== 0) {
      getBillData();
      setGetData(0);
    }
  }, [getData]);

  return (
    <>
      <br></br>
      <Row>
        <Col>
          <Form>
            <Form.Label>Format Type</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setOption(e.target.value)}
            >
              value={option}
              <option key={'option-0'} value={'0'}>
                Original
              </option>
              <option key={'option-1'} value={'1'}>
                Breakup Spans
              </option>
              <option key={'option-2'} value={'2'}>
                Breakup Spans + BRs
              </option>
              <option key={'option-3'} value={'3'}>
                Insert Divs
              </option>
              <option key={'option-4'} value={'4'}>
                Insert Divs + BRs
              </option>
            </Form.Control>
          </Form>
        </Col>
        <Col>
          <Form>
            <Form.Label>Selection Type</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setSelectionType(e.target.value)}
            >
              value={selectionType}
              <option key={'option-1'} value={'1'}>
                Ref
              </option>
              <option key={'option-2'} value={'2'}>
                Target
              </option>
            </Form.Control>
          </Form>
        </Col>
        <Col>
          <Button onClick={() => refreshData()}>Refresh Data</Button>
          <br></br>
          <Button onClick={() => setGetData((prev) => prev + 1)}>
            Get Data
          </Button>
        </Col>
      </Row>
      <br></br>

      <div style={{ position: 'relative', height: '1000px' }}>
        <div
          style={{
            position: 'absolute',
            height: '600px',
            overflow: 'scroll',
            transform: 'scale(1.3)',
            width: '60%',
            top: '100px',
            left: '100px',
          }}
          className='et-pdf-render'
          dangerouslySetInnerHTML={{
            __html: render,
          }}
        />
      </div>
    </>
  );
}
