import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Form, Row, Tabs, Tab } from 'react-bootstrap';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';

import { ApiServiceServerless } from '../../xhr_libs';
import RegressionModal from '../../components/modals/RegressionModal';
import AnalysisChart from './AnalysisChart';
import AnalysisYears from './AnalysisYears';
import RegressionPieces from './RegressionPieces';
import AdditionalAnalysis from './AdditionalAnalysis';
import DataSetSelection from './DataSetSelection';
import REGRESSORS, { IGNORE_REGRESORS } from './regressors';
import ExistingModels from './ExistingModels';

const styles = {
  formStyle: {
    textAlign: 'left',
  },
  actionButtons: {
    height: '60px',
    marginBottom: '0px',
    marginLeft: 'auto',
    marginTop: '100px',
  },
  actionButton: {
    width: '150px',
    marginRight: '16px',
    marginTop: '20px',
  },
  loadButton: {
    width: '150px',
  },
  rowHeader: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '10px',
  },
};

const default_options = { niter: 100, outlier: true, forced_bounds: null };

export default function RegressionAnalysis(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    showToast,
    organization,
    buildings,
    availableUtilities,
    createAccessAnalysis,
    defaultPerformanceProject,
    existingModels,
    addToList,
    updateList,
    removeFromList,
    projects,
    updateAccessAnalysis,
    deleteAccessAnalysis,
    regressionEventCollections,
    regressionEventCollectionsEditted,
  } = deferredProps;

  const [activeTab, setActiveTab] = useState('regression-analysis');

  const [building, setBuilding] = useState('');
  const [utilityType, setUtilityType] = useState('');
  const [regressionType, setRegressionType] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [defaultStartDate, setDefaultStartDate] = useState('');
  const [defaultEndDate, setDefaultEndDate] = useState('');

  const [regressionPieces, setRegressionPieces] = useState([
    { id: 1, regressor: '', model: '' },
  ]);

  const [baselineData, setBaselineData] = useState({});

  const [regressionName, setRegressionName] = useState('');
  const [regressionId, setRegressionId] = useState('');
  const [regressionEvaluation, setRegressionEvaluation] = useState([]);
  const [regressionEvaluationObj, setRegressionEvaluationObj] = useState({});
  const [regressionEvaluationData, setRegressionEvaluationData] = useState({});
  const [regressionStats, setRegressionStats] = useState({});
  const [regressionEquation, setRegressionEquation] = useState('');
  const [regressionCoefficients, setRegressionCoefficients] = useState([]);
  const [regressionLine, setRegressionLine] = useState([]);

  const [regressionOptions, setRegressionOptions] = useState(default_options);
  const [ranRegressionOptions, setRanRegressionOptions] =
    useState(default_options);
  const [ranRegressionPieces, setRanRegressionPieces] = useState([]);
  const [loadedRegressionOptions, setLoadedRegressionOptions] = useState(null);
  const [defaultRegressionOptions, setDefaultRegressionOptions] =
    useState(default_options);
  const [ignoreDateKeys, setIgnoreDateKeys] = useState([]);
  const [ranIgnoreDateKeys, setRanIgnoreDateKeys] = useState([]);
  const [loadedIgnoreDateKeys, setLoadedIgnoreDateKeys] = useState([]);

  const [regressionEventCollection, setRegressionEventCollection] =
    useState(null);
  const [ranRegressionEventCollection, setRanRegressionEventCollection] =
    useState(null);
  const [loadedRegressionEventCollection, setLoadedRegressionEventCollection] =
    useState(null);

  const [localExistingModels, setLocalExistingModels] = useState([]);

  const [showSaveModal, setShowSaveModal] = useState(false);

  const [baselineLoading, setBaselineLoading] = useState(false);
  const [regressionLoading, setRegressionLoading] = useState(false);
  const [existingLoading, setExistingLoading] = useState('-1');

  const [chartRegressor, setChartRegressor] = useState('');
  const [availableRegressors, setAvailableRegressors] = useState([]);
  const [productionNames, setProductionNames] = useState({});

  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    setLocalExistingModels(
      existingModels.filter(
        (model) =>
          model.building_id === parseInt(building) &&
          model.utility_type === utilityType &&
          model.regression_type === regressionType
      )
    );
  }, [existingModels, building, utilityType, regressionType]);

  const addRegressionPieceClick = () => {
    setRegressionPieces((prevRegPieces) => {
      return prevRegPieces.concat({
        id: prevRegPieces.length
          ? prevRegPieces[prevRegPieces.length - 1].id + 1
          : 1,
      });
    });
  };

  const deleteRegressionPieceClick = (id) => {
    if (regressionPieces.length > 1) {
      setRegressionPieces((prevRegPieces) =>
        prevRegPieces.filter((piece) => piece.id !== id)
      );
    } else {
      changeRegressionPiece(id, 'regressor', '');
      changeRegressionPiece(id, 'model', '');
    }
  };

  const changeRegressionPiece = (id, opt, value) => {
    setRegressionPieces((prevRegPieces) =>
      prevRegPieces.map((piece) => {
        if (piece.id === id) piece[opt] = value;
        return piece;
      })
    );
  };

  const filterRegressionPieces = () =>
    regressionPieces.filter((piece) => piece.regressor && piece.model);

  const getBaselineData = (
    utility_type,
    regressionType,
    start_date,
    end_date,
    regression_event_collection = null
  ) => {
    if (!defaultPerformanceProject || (!start_date && !end_date)) {
      showToast(
        'danger',
        'Error',
        'Create a performance project and set baseline date to begin analysis.'
      );
      setUtilityType('');
      setRegressionType('');
    } else {
      const payload = {
        start_date: dayjs.utc(start_date).format('YYYY-MM-DD'),
        end_date: dayjs.utc(end_date).format('YYYY-MM-DD'),
        regression_event_collection: regression_event_collection,
      };
      setBaselineLoading(true);
      setBaselineData([]);

      ApiServiceServerless.post(
        `/analysis/${building}/${utility_type}/${regressionType}`,
        payload,
        {
          timeout: 90000,
          authorization_id: organization.id,
        }
      )
        .then((res) => {
          setBaselineData(res.data.baseline);
          setRegressors(res.data.baseline);
          setBaselineLoading(false);
        })
        .catch((error) => {
          setBaselineLoading(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    }
  };

  const setRegressors = (data) => {
    let available_regressors = [];
    let regressor_names = [];

    const regressor_keys = Object.keys(REGRESSORS);

    Object.keys(data).forEach((key) => {
      if (!IGNORE_REGRESORS.includes(key)) {
        //alphabatize
        if (regressor_keys.includes(key)) {
          regressor_names.push({ key: key, name: REGRESSORS[key] });
        } else {
          regressor_names.push({
            key: key,
            name: data['production_names'][key],
          });
        }
      }
    });
    regressor_names = sortBy(regressor_names, ['name']);
    regressor_names.forEach((el) => {
      available_regressors.push(el['key']);
    });

    setProductionNames(data['production_names']);

    setAvailableRegressors(available_regressors);
    if (!chartRegressor) {
      setChartRegressor(
        available_regressors.includes('temperature') ? 'temperature' : ''
      );
    }
  };

  const runRegression = () => {
    setRegressionLoading(true);
    if (
      baselineData['energy'] &&
      baselineData['energy'].length - ignoreDateKeys.length >= 11
    ) {
      let invalid_baseline = null;
      const pieces = filterRegressionPieces();

      pieces.forEach((piece) => {
        if (
          !baselineData[piece.regressor] ||
          baselineData[piece.regressor].length !== baselineData['energy'].length
        ) {
          invalid_baseline = piece.regressor;
        }
      });
      if (!invalid_baseline) {
        const params = {
          model_list: [['constant', 'linear']].concat(
            filterRegressionPieces().map((piece) => [
              piece.regressor,
              piece.model,
            ])
          ),
          options: regressionOptions,
          start_date: startDate,
          end_date: endDate,
          ignore_date_keys: ignoreDateKeys,
          regression_event_collection: regressionEventCollection,
        };
        ApiServiceServerless.post(
          `/analysis/${building}/${utilityType}/${regressionType}/run`,
          params,
          {
            authorization_id: organization.id,
          }
        )
          .then((res) => {
            setRegressionName('');
            setRegressionEvaluation(res.data['evaluation']);
            setRegressionEvaluationObj(res.data['evaluation_obj']);
            setRegressionEvaluationData(res.data['model_data']);
            setRegressionStats(res.data['stats']);
            setRegressionEquation(res.data['equation']);
            setRegressionCoefficients(res.data['coeffs']);
            setRegressionLoading(false);
            getRegressionLine(
              params['model_list'],
              res.data['coeffs'],
              startDate,
              endDate
            );
            setRanRegressionOptions(regressionOptions);
            setRanRegressionPieces([...regressionPieces]);
            setRanIgnoreDateKeys([...ignoreDateKeys]);
            setRanRegressionEventCollection(regressionEventCollection);
            setSaveDisabled(false);
          })
          .catch((error) => {
            setRegressionLoading(false);
            showToast('danger', 'Error', error);
            throw error;
          });
      } else {
        setRegressionLoading(false);
        showToast(
          'danger',
          'Error',
          'Missing Production Data for ' +
            baselineData['production_names'][invalid_baseline]
        );
      }
    } else {
      setRegressionLoading(false);
      showToast(
        'danger',
        'Error',
        'Need at least 11 months of data to run regression analysis.'
      );
    }
  };

  const getRegressionLine = (model_list, coeffs, start_date, end_date) => {
    if (model_list.length > 2) {
      setRegressionLine([]);
      return;
    }
    const params = {
      model_list: model_list,
      x: coeffs,
      start_date: start_date,
      end_date: end_date,
    };
    ApiServiceServerless.post(
      `/analysis/${building}/${utilityType}/${regressionType}/line`,
      params,
      {
        timeout: 45000,
        authorization_id: organization.id,
      }
    ).then((res) => {
      setRegressionLine(res.data);
    });
  };

  const clearRegression = useCallback(() => {
    setRegressionPieces([{ id: 1, regressor: '', model: '' }]);

    setRegressionName('');
    setRegressionId(0);
    setRegressionEvaluation({});
    setRegressionEvaluationObj({});
    setRegressionEvaluationData({});
    setRegressionStats({});
    setRegressionEquation('');
    setRegressionCoefficients([]);
    setRegressionLine([]);
    setRegressionOptions({ ...default_options });
    setRanRegressionOptions({ ...default_options });
    setIgnoreDateKeys([]);
    setRanIgnoreDateKeys([]);
    setLoadedIgnoreDateKeys([]);
    setRanRegressionPieces([]);
    setLoadedRegressionOptions(null);
    setDefaultRegressionOptions({ ...default_options });
  }, []);

  const clearRegressionEventCollection = () => {
    setRegressionEventCollection(null);
    setLoadedRegressionEventCollection(null);
    setRanRegressionEventCollection(null);
  };

  const resetPage = useCallback(
    (type) => {
      clearRegression();
      clearRegressionEventCollection();
      setBaselineData({});
      if (type === 'org') {
        setBuilding('');
        setUtilityType('');
      }
      if (type === 'building') {
        setUtilityType('');
      }
    },
    [clearRegression]
  );

  useEffect(() => {
    resetPage('org');
  }, [organization, resetPage]);

  useEffect(() => {
    resetPage('building');
  }, [regressionEventCollectionsEditted, resetPage]);

  const saveNewRegression = (name, project_ids, handleSuccess, handleError) => {
    const params = {
      model_list: [['constant', 'linear']].concat(
        filterRegressionPieces().map((piece) => [piece.regressor, piece.model])
      ),
      name: name,
      coeffs: regressionCoefficients,
      stats: regressionStats,
      start_date: startDate,
      end_date: endDate,
      regression_options: ranRegressionOptions,
      ignore_date_keys: ranIgnoreDateKeys,
      regression_event_collection: ranRegressionEventCollection,
      project_ids: project_ids,
    };
    ApiServiceServerless.post(
      `/analysis/${building}/${utilityType}/${regressionType}/save`,
      params,
      {
        authorization_id: organization.id,
      }
    )
      .then((res) => {
        let regression = {};
        regression = res.data[0];
        if (res.data[1]) {
          updateList(res.data[1], 'regression_event_collections');
        }
        if (res.data.length > 2) {
          res.data[2].forEach((reg) => {
            updateList(reg, 'models');
          });
        }
        setRegressionName(name);
        setRegressionId(regression.id);
        addToList(regression, 'models');
        showToast('success', 'Success', 'Your regression was saved.');
        handleSuccess();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        handleError();
        throw error;
      });
  };

  const loadRegression = (regressionId) => {
    setBaselineLoading(true);
    setExistingLoading(regressionId.toString());
    ApiServiceServerless.get(`/analysis/${regressionId}`, {
      authorization_id: organization.id,
    })
      .then(({ data }) => {
        const start_date = dayjs.utc(data.start_date);
        const end_date = dayjs.utc(data.end_date);
        setStartDate(start_date);
        setEndDate(end_date);
        getBaselineData(
          data.utility_type,
          data.regression_type,
          start_date.format('YYYY-MM-DD'),
          end_date.format('YYYY-MM-DD'),
          data.regression_event_collection
        );
        setRegressionName(data.name);
        setRegressionId(data.id);
        setRegressionCoefficients(data.coeffs);
        setRegressionStats(data.stats);
        setRegressionEquation(data.equation);
        setRegressionEvaluation(data.evaluation);
        setRegressionEvaluationObj(data.evaluation_obj);
        setRegressionEvaluationData(data.model_data);
        getRegressionLine(
          data.model_list,
          data.coeffs,
          start_date.format('YYYY-MM-DD'),
          end_date.format('YYYY-MM-DD')
        );
        setRegressionPieces(
          data.model_list
            .map((piece, index) => ({
              id: index,
              regressor: piece[0],
              model: piece[1],
            }))
            .filter((piece) => piece.id !== 0)
        );
        setRanRegressionPieces(
          data.model_list
            .map((piece, index) => ({
              id: index,
              regressor: piece[0],
              model: piece[1],
            }))
            .filter((piece) => piece.id !== 0)
        );
        loadRegressionOptions(data.regression_options);
        setIgnoreDateKeys(data.ignore_date_keys);
        setRanIgnoreDateKeys(data.ignore_date_keys);
        setLoadedIgnoreDateKeys(data.ignore_date_keys);
        setRegressionEventCollection(data.regression_event_collection);
        setRanRegressionEventCollection(data.regression_event_collection);
        setLoadedRegressionEventCollection(data.regression_event_collection);

        if (
          data.model_list.length > 1 &&
          data.model_list[1].length > 0 &&
          availableRegressors.includes(data.model_list[1][0])
        ) {
          setChartRegressor(data.model_list[1][0]);
        }
      })
      .catch(() => {
        showToast('danger', 'Error', 'Error Loading Reregression');
        setBaselineLoading(false);
      })
      .finally(() => {
        setBaselineLoading(false);
        setExistingLoading('-1');
      });
  };

  const loadRegressionOptions = (loaded_options) => {
    let new_options = {};
    let load_keys = Object.keys(loaded_options);
    Object.keys(default_options).forEach((opt) => {
      if (load_keys.includes(opt)) {
        new_options[opt] = loaded_options[opt];
      } else {
        new_options[opt] = default_options[opt];
      }
    });
    setRegressionOptions(new_options);
    setRanRegressionOptions(new_options);
    setLoadedRegressionOptions(new_options);
  };

  //use effect for save disabled, only enable save after a run, changing anything else will disable save
  useEffect(() => {
    setSaveDisabled(true);
  }, [regressionPieces, regressionOptions]);

  return (
    <>
      <DataSetSelection
        organization={organization}
        buildings={buildings}
        availableUtilities={availableUtilities}
        defaultPerformanceProject={defaultPerformanceProject}
        building={building}
        utilityType={utilityType}
        regressionType={regressionType}
        setBuilding={setBuilding}
        setUtilityType={setUtilityType}
        setRegressionType={setRegressionType}
        getBaselineData={getBaselineData}
        setBaselineData={setBaselineData}
        resetPage={resetPage}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        defaultStartDate={defaultStartDate}
        setDefaultStartDate={setDefaultStartDate}
        defaultEndDate={defaultEndDate}
        setDefaultEndDate={setDefaultEndDate}
        localExistingModels={localExistingModels}
        regressionEvaluation={regressionEvaluation}
        regressionName={regressionName}
      />
      <Tabs
        id='analysis-models-tabs'
        activeKey={activeTab}
        onSelect={setActiveTab}
        className='et-second-tabs'
      >
        <Tab eventKey='regression-analysis' title={'Regression Analysis'}>
          <>
            <div style={styles.formStyle}>
              <Row style={{ marginTop: '10px' }}>
                <Col>
                  <AnalysisChart
                    organization_id={organization.id}
                    baselineData={baselineData}
                    utilityType={utilityType}
                    startDate={startDate}
                    endDate={endDate}
                    regressionType={regressionType}
                    evaluation={
                      regressionEvaluation.length ? regressionEvaluation : []
                    }
                    regressionEvaluationData={regressionEvaluationData}
                    regressionLine={regressionLine}
                    stats={regressionStats}
                    equation={regressionEquation}
                    coefficients={regressionCoefficients}
                    baselineLoading={baselineLoading}
                    regressionPieces={regressionPieces}
                    availableRegressors={availableRegressors}
                    productionNames={productionNames}
                    chartRegressor={chartRegressor}
                    setChartRegressor={setChartRegressor}
                    ranRegressionPieces={ranRegressionPieces}
                    ignoreDateKeys={ignoreDateKeys}
                    regressionEventCollections={regressionEventCollections}
                    regressionEventCollection={regressionEventCollection}
                  />
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Row>
                    <div style={styles.rowHeader}>Model Settings </div>{' '}
                  </Row>
                  <Row style={{ marginLeft: '40px', width: '500px' }}>
                    <Col>
                      <Row>
                        <AnalysisYears
                          startDate={startDate}
                          setStartDate={setStartDate}
                          endDate={endDate}
                          setEndDate={setEndDate}
                          utilityType={utilityType}
                          regressionType={regressionType}
                          getBaselineData={getBaselineData}
                          clearRegression={clearRegression}
                          showAdvancedSelect={true}
                          showEventSelect={true}
                          setIgnoreDateKeys={setIgnoreDateKeys}
                          setLoadedIgnoreDateKeys={setLoadedIgnoreDateKeys}
                          loadedIgnoreDateKeys={loadedIgnoreDateKeys}
                          regressionEventCollections={
                            regressionEventCollections
                          }
                          setRegressionCollection={setRegressionEventCollection}
                          loadedRegressionCollection={
                            loadedRegressionEventCollection
                          }
                        ></AnalysisYears>
                      </Row>
                      <Row>
                        <RegressionPieces
                          regressionPieces={regressionPieces}
                          disabled={!utilityType}
                          onAdd={addRegressionPieceClick}
                          onChange={changeRegressionPiece}
                          onDelete={deleteRegressionPieceClick}
                          runRegression={runRegression}
                          disableRun={() =>
                            !utilityType ||
                            !('regressor' in regressionPieces[0]) ||
                            !('model' in regressionPieces[0]) ||
                            regressionPieces[0]['regressor'] === '' ||
                            regressionPieces[0]['model'] === ''
                          }
                          filterRegressionPieces={filterRegressionPieces}
                          regressionLoading={regressionLoading}
                          showToast={showToast}
                          availableRegressors={availableRegressors}
                          productionNames={productionNames}
                          defaultRegressionOptions={defaultRegressionOptions}
                          loadedRegressionOptions={loadedRegressionOptions}
                          setRegressionOptions={setRegressionOptions}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <Row style={styles.actionButtons}>
                    <Form.Group>
                      <Button
                        style={styles.actionButton}
                        variant='outline-danger'
                        onClick={clearRegression}
                      >
                        Clear Model
                      </Button>
                      {createAccessAnalysis && (
                        <Button
                          style={styles.actionButton}
                          variant='success'
                          onClick={() => setShowSaveModal(true)}
                          disabled={saveDisabled}
                        >
                          Save Model
                        </Button>
                      )}
                    </Form.Group>
                  </Row>
                </Col>
              </Row>
            </div>

            <RegressionModal
              variant='save'
              show={showSaveModal}
              onHide={() => setShowSaveModal(false)}
              saveRegression={saveNewRegression}
              utilityType={utilityType}
              building_id={building}
              projects={projects.filter(
                (project) => project.project_type === 'performance'
              )}
            />
          </>
        </Tab>
        <Tab eventKey='existing-models' title={'Existing Models'}>
          <ExistingModels
            organization_id={organization.id}
            building_id={building}
            utilityType={utilityType}
            regressionType={regressionType}
            existingModels={existingModels}
            projects={projects}
            handleLoadRegression={(reg) => {
              loadRegression(reg);
            }}
            updateList={updateList}
            removeFromList={removeFromList}
            updateAccess={updateAccessAnalysis}
            deleteAccess={deleteAccessAnalysis}
            showToast={showToast}
            isLoading={existingLoading}
          />
        </Tab>
        <Tab eventKey='additional-analysis' title={'Additional Analysis'}>
          <AdditionalAnalysis
            organization_id={organization.id}
            baselineData={baselineData}
            regressionEvaluation={regressionEvaluation}
            regressionEvaluationObj={regressionEvaluationObj}
            regressionEvaluationData={regressionEvaluationData}
            regressionLine={regressionLine}
            utilityType={utilityType}
            regressionType={regressionType}
            availableRegressors={availableRegressors}
            productionNames={productionNames}
            chartRegressor={chartRegressor}
            setChartRegressor={setChartRegressor}
            ranRegressionPieces={ranRegressionPieces}
            filterRegressionPieces={filterRegressionPieces}
            building={building}
            showToast={showToast}
            regressionEquation={regressionEquation}
            regressionStats={regressionStats}
            regressionCoefficients={regressionCoefficients}
            regressionName={regressionName}
            regressionId={regressionId}
            regressionOptions={ranRegressionOptions}
            existingModels={localExistingModels}
            ranRegressionEventCollection={ranRegressionEventCollection}
            ranRegressionStartDate={startDate}
          />
        </Tab>
      </Tabs>
    </>
  );
}
