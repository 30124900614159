export default function findElementPath() {
  const old_parent = document.querySelector('.et-pdf-nearest-parent');
  if (old_parent) {
    old_parent.classList.remove('et-pdf-nearest-parent');
  }

  const ref = document.querySelector('.et-pdf-selected-el[selection="1"]');
  const target = document.querySelector('.et-pdf-selected-el[selection="2"]');

  if (!ref || !target) {
    console.log('Need two selections');
  } else {
    console.log('Finding Path');
    const [nearest_parent, refStep, targetStep] = findNearestParent(
      ref,
      target
    );
    console.log(nearest_parent, refStep, targetStep);
    nearest_parent.classList.add('et-pdf-nearest-parent');
  }
}

function findNearestParent(ref, target) {
  const root = document.querySelector('.et-pdf-render');
  const refTree = getPathToRoot(root, ref);
  const targetTree = getPathToRoot(root, target);

  let nearest_parent = root;
  let refIdx = refTree.length - 1;
  let targetIdx = targetTree.length - 1;
  let rootStep = 0;

  while (refIdx >= 0 && targetIdx >= 0) {
    if (refTree[refIdx] === targetTree[targetIdx]) {
      nearest_parent = refTree[refIdx];
      rootStep++;
    }

    refIdx--;
    targetIdx--;
  }
  return [
    nearest_parent,
    refTree.length - rootStep,
    targetTree.length - rootStep,
  ];
}

function getPathToRoot(root, el) {
  const tree = [];

  let temp_el = el;
  while (temp_el.parentElement !== root) {
    tree.push(temp_el.parentElement);
    temp_el = temp_el.parentElement;
  }

  return tree;
}

const meter_id = '133849336LG';
const sideRef = 'Metered:';

const value_lookup = {
  start_date: 5,
  read_date: 6,
  kwh: 11,
  kw: 12,
  cost1: 14,
  cost2: 15,
  cost3: 16,
  cost4: 17,
  cost5: 18,
  cost6: 19,
};

export function getBillData() {
  const ref_cell = findReferenceCell();
  console.log(ref_cell);
  let bill_data = {};

  Object.keys(value_lookup).forEach((key) => {
    let val = '';
    if (ref_cell) {
      val = getSiblingCellValue(ref_cell, value_lookup[key]);
    }
    bill_data[key] = val;
  });
  console.log(bill_data);
}

function findReferenceCell() {
  const els = document.querySelectorAll('.et-pdf-content-el');
  for (let i = 0; i < els.length; i++) {
    if (els[i].innerText.startsWith(meter_id)) {
      let ref = els[i];
      if (checkSideReference(ref)) {
        return ref;
      }
    }
  }
}

function getSiblingCellValue(ref_cell, sib_step) {
  let value_cell = ref_cell.parentElement;
  for (let i = 0; i < sib_step; i++) {
    value_cell = value_cell.nextSibling;
  }
  value_cell = value_cell.firstChild;
  return value_cell.innerText.replace('\n', '');
}

function checkSideReference(ref) {
  let side_ref = ref.previousSibling;
  return side_ref.innerText.startsWith(sideRef);
}
