import dayjs from 'dayjs';

const CSV_PAYLOAD_OPTS = {
  headers: {
    Accept: 'application/vnd.openxmlformats-',
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
};

const PDF_PAYLOAD_OPTS = {
  headers: {
    Accept: 'application/pdf',
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
};

//report serivce
// 1 is old flask api (DEPRECATED)
// 2 is normal fast api return method
// 3 is lambda report serivce for FPDF reports

export const ReportLookup = [
  {
    type: 'excel',
    primaryName: 'Raw Data Report',
    variant: 'basic',
    reportInfo: [
      {
        type: 'excel',
        name: 'Excel Data',
        fileExtension: 'xlsx',
        header: 'Excel Data',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 2,
      },
      {
        type: 'excel_provider',
        name: 'Provider Data',
        fileExtension: 'xlsx',
        header: 'Provider Data',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 2,
      },
      {
        type: 'excel_portfolio_manager',
        name: 'Portfolio Manager',
        fileExtension: 'xlsx',
        header: 'Portfolio Manager',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 2,
      },
    ],
  },
  {
    type: 'trend',
    primaryName: 'Trend Report',
    variant: 'basic',
    reportInfo: [
      {
        type: 'trend',
        name: 'Organization',
        fileExtension: 'pdf',
        header: 'Trend Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
      {
        type: 'trend_excel',
        name: 'Excel',
        fileExtension: 'xlsx',
        header: 'Trend Report (Excel)',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 2,
      },
      {
        type: 'trend_building',
        name: 'Building',
        fileExtension: 'pdf',
        header: 'Trend Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
    ],
  },
  {
    type: 'usage',
    primaryName: 'Usage Report',
    variant: 'basic',
    reportInfo: [
      {
        type: 'usage',
        name: 'Portrait',
        fileExtension: 'pdf',
        header: 'Usage Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
      {
        type: 'usage_landscape',
        name: 'Landscape',
        fileExtension: 'pdf',
        header: 'Usage Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
    ],
  },
  {
    type: 'transcript',
    primaryName: 'Transcript Report',
    variant: 'basic',
    reportInfo: [
      {
        type: 'transcript',
        name: 'Transcript Report',
        fileExtension: 'pdf',
        header: 'Transcript Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
    ],
  },
  {
    type: 'performance',
    primaryName: 'Performance Report',
    variant: 'advanced',
    reportInfo: [
      {
        type: 'performance',
        name: 'Standard',
        fileExtension: 'pdf',
        header: 'Performance Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
      {
        type: 'performance_excel',
        name: 'Excel',
        fileExtension: 'xlsx',
        header: 'Performance Report (Excel)',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 2,
      },
      {
        type: 'performance_building',
        name: 'Building Resource',
        fileExtension: 'pdf',
        header: 'Building Resource Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
    ],
  },

  {
    type: 'analysis',
    primaryName: 'Analysis Report',
    variant: 'advanced',
    reportInfo: [
      {
        type: 'analysis',
        name: 'Standard',
        fileExtension: 'pdf',
        header: 'Analysis Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
      {
        type: 'analysis_excel',
        name: 'Excel',
        fileExtension: 'xlsx',
        header: 'Analysis Report',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
    ],
  },
  {
    type: 'feasibility',
    primaryName: 'Utility Analysis and Benchmark Report',
    variant: 'advanced',
    reportInfo: [
      {
        type: 'feasibility',
        name: 'Standard',
        fileExtension: 'pdf',
        header: 'Utility Analysis and Benchmark Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
      {
        type: 'feasibility_excel',
        name: 'Excel',
        fileExtension: 'xlsx',
        header: 'Utility Analysis and Benchmark Report (Excel)',
        opts: CSV_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 2,
      },
    ],
  },
  {
    type: 'operational_savings',
    primaryName: 'Operational Savings Report',
    variant: 'advanced',
    reportInfo: [
      {
        type: 'operational_savings',
        name: 'Operational Savings Report',
        fileExtension: 'pdf',
        header: 'Operational Savings Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
    ],
  },
  {
    type: 'ghg_summary',
    primaryName: 'Greenhouse Gas Summary Report',
    variant: 'ghg',
    reportInfo: [
      {
        type: 'ghg_summary',
        name: 'Greenhouse Gas Summary Report',
        fileExtension: 'pdf',
        header: 'Greenhouse Gas Summary Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
    ],
  },

  {
    type: 'carbon_transcript',
    primaryName: 'Carbon Transcript Report',
    variant: 'ghg',
    reportInfo: [
      {
        type: 'carbon_transcript',
        name: 'Carbon Transcript Report',
        fileExtension: 'pdf',
        header: 'Carbon Transcript Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: false,
        report_service: 3,
      },
    ],
  },
  {
    type: 'ghg_baseline',
    primaryName: 'Greenhouse Gas Baseline Report',
    variant: 'ghg',
    reportInfo: [
      {
        type: 'ghg_baseline',
        name: 'Greenhouse Gas Baseline Report',
        fileExtension: 'pdf',
        header: 'Greenhouse Gas Baseline Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
    ],
  },
  {
    type: 'ghg_glide_path',
    primaryName: 'Greenhouse Gas Glide Path Report',
    variant: 'ghg',
    reportInfo: [
      {
        type: 'ghg_glide_path',
        name: 'Greenhouse Gas Glide Path Report',
        fileExtension: 'pdf',
        header: 'Greenhouse Gas Glide Path Report',
        opts: PDF_PAYLOAD_OPTS,
        building_hide: true,
        report_service: 3,
      },
    ],
  },
];

export const ReportTypes = ['usage', 'cost', 'ghg'];
export const ReportTypesNoGHG = ['usage', 'cost'];

export function getReportTypes(activeGHGLicense) {
  if (activeGHGLicense) {
    return ReportTypes;
  } else {
    return ReportTypesNoGHG;
  }
}

export const NoReportsTypes = [
  'analysis',
  'operational_savings',
  'carbon_transcipt',
];
export const NoDataReports = ['operational_savings', 'carbon_transcipt'];

export const SavingsDateReports = [
  'performance',
  'analysis',
  'performance_building',
  'performance_excel',
];

export const TrendReports = ['trend', 'trend_excel', 'trend_building'];

export const ReportTypesLookup = {
  usage: 'Usage',
  cost: 'Cost',
  ghg: 'Emissions',
};

export const UtilityTypesLookup = {
  electric: 'Electric',
  gas: 'Gas',
  propane: 'Propane',
  water: 'Water',
  production: 'Production',
};

export const GHGTypesLookup = {
  electric: 'Electric',
  gas: 'Gas',
  propane: 'Propane',
};

export function generateSimpleFilename(organization_name, report) {
  return [
    organization_name +
      ' ' +
      report['header'] +
      ' - ' +
      dayjs.utc(new Date()).format('MMM-DD-YYYY'),
    report['fileExtension'],
  ].join('.');
}

export const DateOptions = {
  0: 'Calendar Month',
  1: 'Bill Date',
  2: 'Read Date',
};
