import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

const styles = {
  card: { height: '11.25em' },
  text: {
    position: 'absolute',
    bottom: '2.5em',
    marginLeft: '-1em',
    width: '100%',
    textAlign: 'center',
    fontSize: '1.1em',
  },
};

class WidgetText extends React.Component {
  render() {
    const { title, value } = this.props;
    return (
      <Card style={styles.card}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text style={styles.text}>{value}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

WidgetText.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default WidgetText;
