import React, { useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';

import AddEntityActionButtons from '../forms/AddEntityActionButtons';

const styles = {
  formStyle: {
    textAlign: 'left',
  },
};

export default function LicenseReportRecipientModal(props) {
  const { show, onHide, saveRecipient, isSaving } = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [validated, setValidated] = useState(false);

  const clearForm = () => {
    setName('');
    setEmail('');
    setValidated(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      saveRecipient(name, email);
      clearForm();
    } else {
      setValidated(true);
    }
  };

  const handleCancel = () => {
    onHide();
    clearForm();
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton style={{ height: '50px' }}>
          {'Add a Recipient'}
        </Modal.Header>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          style={styles.formStyle}
        >
          <Modal.Body>
            <Row>
              <Col sm={12}>
                <Form.Group>
                  <Form.Label>
                    Name
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Email
                    <span className='text-warning font-weight-bold pl-1'>
                      *
                    </span>
                  </Form.Label>
                  <Form.Control
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <AddEntityActionButtons
              onCancel={handleCancel}
              submitDisabled={name === '' || email === ''}
              isSubmitting={isSaving}
            />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
