import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import {
  BarChart,
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  Bar,
  Legend,
} from 'recharts';

import { ApiServiceServerless } from '../../xhr_libs';
import Loader from '../../components/Loader';
import { CHARTCOLORS_EXTENDED } from '../../helpers/chartColors';
import formatValue from '../../helpers/chartFormatter';

const filter_keys = { stack: 'Stack', env: 'Env' };

export default function AdminCostData() {
  const [filterKey, setFilterKey] = useState('stack');
  const [chartKeys, setChartKeys] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const formatChartData = useCallback((rawData, filter_key) => {
    let temp_keys = [];
    let chart_data = [];
    rawData.forEach((day) => {
      let temp_day = {
        name: day['TimePeriod']['Start'],
      };
      day['Groups'].forEach((cost) => {
        let temp_key = formatKey(cost['Keys'][0], filter_key);
        temp_day[temp_key] = cost['Metrics']['UnblendedCost']['Amount'] * 100;
        if (!temp_keys.includes(temp_key)) {
          temp_keys.push(temp_key);
        }
      });
      chart_data.push(temp_day);
    });
    setChartKeys(temp_keys);
    setChartData(chart_data);
  }, []);

  const formatKey = (key, filter_key) => {
    let formmatted_key = key.split(filter_key + '$')[1];
    return formmatted_key === '' ? 'other' : formmatted_key;
  };

  useEffect(() => {
    setIsLoading(true);
    ApiServiceServerless.get(`/admin/costs/${filterKey}`)
      .then(({ data }) => {
        formatChartData(data['ResultsByTime'], filterKey);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        throw err;
      });
  }, [formatChartData, filterKey]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Form.Group style={{ width: '15vw' }}>
            <Form.Label> Filter Option</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => setFilterKey(e.target.value)}
              value={filterKey}
            >
              {Object.keys(filter_keys).map((key, index) => (
                <option key={index} value={key}>
                  {filter_keys[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <ResponsiveContainer width='100%' height={300} debounce={300}>
            <BarChart data={chartData}>
              <YAxis
                type='number'
                tickFormatter={(tick) => {
                  return tick.toLocaleString();
                }}
              />
              <XAxis dataKey='name' />
              <Tooltip />
              <Legend wrapperStyle={{ bottom: '0px', position: 'relative' }} />
              {chartKeys.map((key, idx) => (
                <Bar
                  dataKey={key}
                  fill={CHARTCOLORS_EXTENDED[idx % CHARTCOLORS_EXTENDED.length]}
                  formatter={(value) => formatValue(value, '¢')}
                  stackId='a'
                  key={key + '_' + idx}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
}
