import React, { useEffect, useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import Helmet from 'react-helmet';

import { useTabUrl } from '../../helpers/tracked';
import GHGTool from './GHGTool';
import BillTool from './BillTool';

const pageTitle = 'Tools';
export default function Tools(props) {
  const {
    showToast,
    userSelectedOrganization,
    userSelectedOrganizationDetails,
  } = props;
  const [activeTab, setActiveTab] = useTabUrl('ghg-tool');
  const [activeGHGLicense, setActiveGHGLicense] = useState(false);

  const [activeAnalysisLicense, setActiveAnalysisLicense] = useState(false);
  const [analysisLicenseId, setAnalysisLicenseId] = useState(null);

  useEffect(() => {
    let active_ghg_license = false;
    let active_analysis_license = false;
    let analysis_license_id = null;
    if (userSelectedOrganizationDetails.id) {
      if (
        userSelectedOrganizationDetails &&
        userSelectedOrganizationDetails.licenses &&
        userSelectedOrganizationDetails.licenses.ghg
      ) {
        active_ghg_license =
          userSelectedOrganizationDetails.licenses.ghg.status === 'active';
      }
      if (
        userSelectedOrganizationDetails &&
        userSelectedOrganizationDetails.licenses &&
        userSelectedOrganizationDetails.licenses.analysis
      ) {
        active_analysis_license =
          userSelectedOrganizationDetails.licenses.analysis.status === 'active';
        analysis_license_id = userSelectedOrganizationDetails.id;
      }
    }

    setActiveGHGLicense(active_ghg_license);
    setActiveAnalysisLicense(active_analysis_license);
    setAnalysisLicenseId(analysis_license_id);
  }, [userSelectedOrganizationDetails]);

  return (
    <Container className='et-container et-main-tab-content'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tabs
        id='tools-tabs'
        activeKey={activeTab}
        onSelect={setActiveTab}
        className='et-main-tabs'
      >
        <Tab eventKey='ghg-tool' title='GHG Tool'>
          {activeGHGLicense ? (
            <GHGTool
              showToast={showToast}
              organizationId={userSelectedOrganization.id}
            />
          ) : (
            <div>
              This organization does not have a valid license to access this
              feature. Please contact an administrator about licensing.
            </div>
          )}
        </Tab>

        <Tab eventKey='bill-tool' title='Bill Tool'>
          {activeAnalysisLicense ? (
            <BillTool
              organizationId={userSelectedOrganization.id}
              showToast={showToast}
              activeAnalysisLicense={activeAnalysisLicense}
              analysisLicenseId={analysisLicenseId}
            />
          ) : (
            <div>
              This organization does not have a valid license to access this
              feature. Please contact an administrator about licensing.
            </div>
          )}
        </Tab>
      </Tabs>
    </Container>
  );
}
