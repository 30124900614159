import React from 'react';
import { Button, Container } from 'react-bootstrap';

import SmallerLoader from '../SmallerLoader';

const footerStyle = {
  backgroundColor: '#F8F8F8',
  borderTop: '1px solid #E7E7E7',
  textAlign: 'center',
  padding: '0.5rem',
  position: 'fixed',
  left: '0',
  bottom: '32px',
  width: '100%',
  zIndex: 1000, // cover focused inputs
};

function EditBillsFooter(props) {
  const { saveEditedBills, isSaving } = props;

  return (
    <div style={footerStyle}>
      <Container>
        <Button
          variant='primary'
          disabled={isSaving}
          onClick={() => saveEditedBills()}
        >
          {!isSaving && 'Save Edited Bill(s)'}
          {isSaving && <SmallerLoader text={'Saving...'} />}
        </Button>
      </Container>
    </div>
  );
}

export default EditBillsFooter;
