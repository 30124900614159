import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/pro-solid-svg-icons';

import EnergyTracerTable from '../../../components/tables';
import EditUtilityAccountModal from '../../../components/modals/UtilityAccountModals/EditUtilityAccountModal';

const styles = {
  buttonDiv: { textAlign: 'right' },
  iconStyle: {
    fontSize: '16px',
    margin: '0 0.35em',
    verticalAlign: 'text-bottom',
    cursor: 'pointer',
    color: 'var(--et_tab_grey)',
    opacity: '1.0',
  },
};

export default function BuildingAccountsTable(props) {
  const {
    building,
    updateAccess,
    setBuilding,
    setSelectedResource,
    setUtilityAccountInfo,
    handleUpdateTables,
    showToast,
  } = props;

  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showAccountModalFlag, setShowAccountModalFlag] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});

  const handleEditAccount = (id) => {
    setSelectedAccount(building.accounts.find((acc) => acc.id === id));
    setShowAccountModalFlag(true);
  };

  const handleSetAccount = (data) => {
    setBuilding((prev) => ({
      ...prev,
      accounts: prev.accounts.map((account) => {
        if (account.id !== data.id) return account;
        return data;
      }),
    }));
  };

  useEffect(() => {
    if (showAccountModalFlag) {
      setShowAccountModal(true);
    }
  }, [showAccountModalFlag]);

  const setUtilityAccount = (row) => {
    setSelectedResource('utilityAccount');
    setUtilityAccountInfo({ id: row.id, name: row.account_number });
  };

  const tableColumns = [
    {
      dataField: 'account.account_number',
      text: 'Account',
      formatter: (cell, row) => (
        <div
          className='et-link'
          onClick={() => {
            setUtilityAccount(row);
          }}
        >
          {row.account_number}
        </div>
      ),
    },
    {
      dataField: 'account.account_type',
      text: 'Utility Accounts',
      formatter: (cell, row) => (
        <div
          className='et-link'
          onClick={() => {
            setUtilityAccount(row);
          }}
        >
          {row.account_type.charAt(0).toUpperCase() +
            row.account_type.substr(1).toLowerCase()}
        </div>
      ),
    },
    {
      dataField: 'building.id',
      text: '',
      formatter: (cell, row) => (
        <div style={styles.buttonDiv}>
          {updateAccess && (
            <FontAwesomeIcon
              style={styles.iconStyle}
              icon={faPen}
              title={'Edit Building'}
              onClick={() => handleEditAccount(row.id)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <EnergyTracerTable
        keyField='id'
        columns={tableColumns}
        data={building.accounts}
      />
      <EditUtilityAccountModal
        accountDetails={selectedAccount}
        organization={building.organization}
        buildings={building.siblings}
        defaultBuildings={selectedAccount.building_ids}
        show={showAccountModal}
        onHide={() => {
          setSelectedAccount({});
          setShowAccountModal(false);
          setShowAccountModalFlag(false);
        }}
        handleSetAccount={handleSetAccount}
        handleUpdateTables={handleUpdateTables}
        showToast={showToast}
      />
    </>
  );
}
