import { get } from 'lodash';

const OBJECT_NAME = 'energytracer_user_preferences';

const getUserPreferencesObject = () => {
  if (!localStorage.getItem(OBJECT_NAME))
    localStorage.setItem(OBJECT_NAME, JSON.stringify({}));
  return JSON.parse(localStorage.getItem(OBJECT_NAME));
};

const setUserPreferencesObject = (prefObj) =>
  localStorage.setItem(OBJECT_NAME, JSON.stringify(prefObj));

export const getUserPreference = (key) => {
  const prefsObject = getUserPreferencesObject();
  switch (key) {
    case 'account_type_filter':
      return parseInt(get(prefsObject, key, 0));
    case 'last_selected_account':
      return parseInt(get(prefsObject, key, 0));
    case 'last_filtered_org':
      return get(prefsObject, key, '');
    default:
      throw Error(`Invalid user preference key: ${key}`);
  }
};

export const setUserPreference = (key, value) => {
  const prefsObject = getUserPreferencesObject();
  switch (key) {
    case 'account_type_filter':
      setUserPreferencesObject({ ...prefsObject, [key]: parseInt(value) });
      break;
    case 'last_selected_account':
      setUserPreferencesObject({ ...prefsObject, [key]: parseInt(value) });
      break;
    case 'last_filtered_org':
      setUserPreferencesObject({ ...prefsObject, [key]: value });
      break;
    default:
      throw Error(`Invalid user preference key: ${key}`);
  }
};
