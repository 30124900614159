import React, { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';

const UPDATE_OPTIONS = {
  credential: {
    option: 'Account',
    url: '/admin/update_accounts',
  },
  account: {
    option: 'Organization',
    url: '/admin/update_org_accounts',
  },
};

export default function AdminUtilityAccountTools(props) {
  const { showToast, isGenerating, setIsGenerating } = props;

  const [updateOption, setUPdateOption] = useState(null);
  const [updateId, setUpdateId] = useState(null);

  useEffect(() => {
    setUpdateId(null);
  }, [updateOption]);

  const handleUpdateResource = () => {
    setIsGenerating(true);
    let url = UPDATE_OPTIONS[updateOption]['url'] + '/' + updateId;
    ApiServiceServerless.get(url)
      .then(() => {
        showToast(
          'success',
          'Success',
          'Repull in progress. Please check back in a few minutes'
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  return (
    <>
      <br></br>
      <br></br>
      <h3>Utility Account Tools</h3>
      <Form>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Update Option</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setUPdateOption(e.target.value);
              }}
              value={updateOption}
            >
              <option hidden key={'select-option'} value={null}></option>
              {Object.keys(UPDATE_OPTIONS).map((key) => (
                <option key={key} value={key}>
                  {UPDATE_OPTIONS[key]['option']}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {updateOption && (
            <Form.Group as={Col}>
              <Form.Label>
                {UPDATE_OPTIONS[updateOption]['option']} ID
              </Form.Label>
              <Form.Control
                value={updateId ? updateId : ''}
                onChange={(e) => setUpdateId(e.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md={2}>
            <Form.Label>Action</Form.Label>
            <Form.Control
              as={Button}
              disabled={!updateOption || !updateId || isGenerating}
              onClick={() => {
                handleUpdateResource();
              }}
            >
              Update Resource
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
}
