import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import AppContext from '../../contexts/app-context';
import Loader from '../../components/Loader';

import { ApiServiceServerless } from '../../xhr_libs';
import AdminPlugTools from './AdminPlugTools';
import AdminUtilityAccountTools from './AdminUtilityAccountTools';

const report_opts = {
  headers: {
    Accept: 'application/vnd.openxmlformats-',
    'Content-Type': 'application/json',
  },
  responseType: 'arraybuffer',
};

function AdminTools() {
  const { showToast } = useContext(AppContext);

  const [isGenerating, setIsGenerating] = useState(false);

  const getLicenseReport = () => {
    setIsGenerating(true);
    ApiServiceServerless.get(`/admin/license_report`, {
      ...report_opts,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const old_link = document.getElementById(
          'license-report-report-download-link'
        );
        if (old_link) {
          old_link.remove();
        }

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.id = 'license-report-report-download-link';
        link.setAttribute('download', 'License Report.xlsx');
        document.body.appendChild(link);
      })
      .then(() => {
        const link = document.getElementById(
          'license-report-report-download-link'
        );
        link.click();
        setIsGenerating(false);
      })
      .catch((error) => {
        setIsGenerating(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const sendReportEmail = () => {
    setIsGenerating(true);
    ApiServiceServerless.get(`/admin/license_report_email`)
      .then(() => {
        setIsGenerating(false);
        showToast('success', 'Success', 'Emails Sent');
      })
      .catch((error) => {
        setIsGenerating(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const getUserReport = () => {
    setIsGenerating(true);
    ApiServiceServerless.get(`/admin/user_report`, { ...report_opts })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const old_link = document.getElementById(
          'user-report-report-download-link'
        );
        if (old_link) {
          old_link.remove();
        }

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.id = 'user-report-report-download-link';
        link.setAttribute('download', 'User Report.xlsx');
        document.body.appendChild(link);
      })
      .then(() => {
        const link = document.getElementById(
          'user-report-report-download-link'
        );
        link.click();
        setIsGenerating(false);
      })
      .catch((error) => {
        setIsGenerating(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const getProviderBreakdownReport = () => {
    setIsGenerating(true);
    ApiServiceServerless.get(`/admin/provider_breakdown_report`, {
      ...report_opts,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const old_link = document.getElementById(
          'provider-report-report-download-link'
        );
        if (old_link) {
          old_link.remove();
        }

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.id = 'provider-report-report-download-link';
        link.setAttribute('download', 'Provider Breakdown Report.xlsx');
        document.body.appendChild(link);
      })
      .then(() => {
        const link = document.getElementById(
          'provider-report-report-download-link'
        );
        link.click();
        setIsGenerating(false);
      })
      .catch((error) => {
        setIsGenerating(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const getProviderOrganizationReport = () => {
    setIsGenerating(true);
    ApiServiceServerless.get(`/admin/provider_organization_report`, {
      ...report_opts,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const old_link = document.getElementById(
          'provider-report-report-download-link'
        );
        if (old_link) {
          old_link.remove();
        }

        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.id = 'provider-report-report-download-link';
        link.setAttribute('download', 'Provider Organization Report.xlsx');
        document.body.appendChild(link);
      })
      .then(() => {
        const link = document.getElementById(
          'provider-report-report-download-link'
        );
        link.click();
        setIsGenerating(false);
      })
      .catch((error) => {
        setIsGenerating(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  const callLicenseFunction = (url) => {
    setIsGenerating(true);
    ApiServiceServerless.get(`/admin/${url}_licenses`)
      .then(() => {
        setIsGenerating(false);
        showToast('success', 'Success', 'Licenses Updating');
      })
      .catch((error) => {
        setIsGenerating(false);
        showToast('danger', 'Error', error);
        throw error;
      });
  };

  return (
    <>
      {isGenerating && <Loader />}
      <h3>Report Functions</h3>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => getLicenseReport()}
      >
        Download License Report
      </Button>
      <br></br>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => getUserReport()}
      >
        Download User Report
      </Button>
      <br></br>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => getProviderBreakdownReport()}
      >
        Download Provider Breakdown Report
      </Button>
      <br></br>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => getProviderOrganizationReport()}
      >
        Download Provider Organization Report
      </Button>
      <br></br>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => sendReportEmail()}
      >
        Send Licenses Report Emails
      </Button>
      <br></br>
      <br></br>
      <h3>License Functions</h3>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => callLicenseFunction('deactivate')}
      >
        Deactivate Expired Licenses
      </Button>
      <br></br>
      <Button
        style={{ marginTop: '15px' }}
        disabled={isGenerating}
        onClick={() => callLicenseFunction('reactivate')}
      >
        Reactivate Non-Expired Licenses
      </Button>
      <AdminUtilityAccountTools
        showToast={showToast}
        isGenerating={isGenerating}
        setIsGenerating={setIsGenerating}
      />
      <AdminPlugTools
        showToast={showToast}
        isGenerating={isGenerating}
        setIsGenerating={setIsGenerating}
      />
    </>
  );
}

export default AdminTools;
