import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faX } from '@fortawesome/pro-solid-svg-icons';

function ResourceActionButtons(props) {
  const {
    resource_name,
    updateAccess,
    deleteAccess,
    showEditModal,
    handleDelete,
    disabled,
    right,
    top,
  } = props;

  let edit_disabled = !updateAccess || disabled;
  let delete_disabled = !deleteAccess || disabled;

  const getStyles = (disabled) => {
    return {
      iconStyle: {
        fontSize: '16px',
        margin: '0 0.35em',
        verticalAlign: 'text-bottom',
        cursor: disabled ? 'none' : 'pointer',
        color: 'var(--et_tab_grey)',
        opacity: disabled ? '0.5' : '1.0',
      },
    };
  };
  return (
    <div style={{ position: 'absolute', right: right, top: top }}>
      <FontAwesomeIcon
        style={getStyles(edit_disabled).iconStyle}
        icon={faPen}
        title={'Edit ' + resource_name}
        disabled={edit_disabled}
        onClick={() => {
          if (!edit_disabled) {
            showEditModal();
          }
        }}
      />
      <FontAwesomeIcon
        style={getStyles(delete_disabled).iconStyle}
        icon={faX}
        title={'Delete ' + resource_name}
        disabled={delete_disabled}
        onClick={() => {
          if (!delete_disabled) {
            handleDelete();
          }
        }}
      />
    </div>
  );
}

ResourceActionButtons.defaultProps = {
  top: '0%',
  right: '1.5%',
};

export default ResourceActionButtons;
