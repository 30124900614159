import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';

import LoginPageDecoration from '../components/LoginPageDecoration';
import ReCaptcha, { withReCaptcha } from '../components/ReCaptcha';
import InvalidFeedback from '../components/forms/InvalidFeedback';
import { ApiServicePublicServerless } from '../xhr_libs';
import { setAuthToken } from '../auth';

import Helmet from 'react-helmet';
import SmallerLoader from '../components/SmallerLoader';
const pageTitle = 'Sign In';

function Login(props) {
  const { showToast } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { register, errors, handleSubmit } = useForm();
  const recaptchaRef = useRef();

  const onSubmit = (data) => {
    setIsLoading(true);
    withReCaptcha(() => {
      ApiServicePublicServerless.post('/auth', {
        username: data.username,
        password: data.password,
      })
        .then((response) => {
          setAuthToken(response);
          return response;
        })
        .catch((error) => {
          setIsLoading(false);
          showToast('danger', 'Error', error);
          throw error;
        });
    }, recaptchaRef);
  };

  return (
    <>
      <ReCaptcha ref={recaptchaRef} />
      <LoginPageDecoration />
      <Container
        style={{ marginTop: '2em', width: '450px' }}
        className='et-unauth-container'
      >
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Card style={{ border: 'none' }}>
          <Card.Body className={'login-cardbody'}>
            <h4 className={'mb-4'}>{pageTitle}</h4>
            <Form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              style={{ textAlign: 'left' }}
            >
              <Form.Group>
                <Form.Label>
                  Email
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  type='email'
                  name='username'
                  placeholder=''
                  isInvalid={errors.username}
                  ref={register({ required: true, pattern: /^\S+@\S+$/i })}
                />
                <InvalidFeedback
                  errors={errors}
                  name='username'
                  message='Please provide a valid email address'
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  Password
                  <span className='text-warning font-weight-bold pl-1'>*</span>
                </Form.Label>
                <Form.Control
                  type='password'
                  name='password'
                  placeholder=''
                  isInvalid={errors.password}
                  ref={register({ required: true, minLength: 8 })}
                />
                <InvalidFeedback
                  errors={errors}
                  name='password'
                  message='Password must be at least 8 characters long'
                />
              </Form.Group>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '15px',
                  }}
                  type='submit'
                  disabled={isLoading}
                >
                  {!isLoading && 'Sign in'}
                  {isLoading && <SmallerLoader text={'Signing in'} />}
                </Button>
                <Link to='/forgotPassword'>Forgot password?</Link>
                <hr style={{ width: '100%' }} />
                <Link
                  to='/register'
                  style={{
                    width: '100%',
                    marginTop: '5px',
                    alignSelf: 'center',
                  }}
                  disabled={isLoading}
                >
                  <Button
                    variant={'success'}
                    style={{ width: '100%' }}
                    disabled={isLoading}
                  >
                    Create a new account
                  </Button>
                </Link>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Login);
