import React, { useState, useEffect } from 'react';
import { Col, Form, Modal, Row, Alert } from 'react-bootstrap';
import { cloneDeep } from 'lodash';

import { ApiServiceServerless } from '../../xhr_libs';

import EditEntityActionButtons from '../forms/EditEntityActionButtons';
import LicenseDatePicker from '../../pages/Admin/LicenseDatePicker';
import dayjs from 'dayjs';

const EMPTY_LICENSE = {
  id: 0,
  name: 'none',
  has_billing_contact: false,
  licenses: {},
};

export default function AdminLicenseModal(props) {
  const { show, onHide, currentLicense, licenseTableSetter } = props;

  const [isSaving, setIsSaving] = useState(false);

  const [newLicense, setNewLicense] = useState({
    ...EMPTY_LICENSE,
  });
  const [hasChange, setHasChange] = useState(false);

  useEffect(() => {
    setNewLicense(cloneDeep(currentLicense));
  }, [currentLicense, show]);

  const handleDateChange = (type, newDate) => {
    const status = dayjs.utc(newDate) > dayjs.utc() ? 'active' : 'expired';
    const updatedLicense = cloneDeep(newLicense);
    updatedLicense['licenses'][type]['status'] = status;
    updatedLicense['licenses'][type]['expiration'] = dayjs
      .utc(newDate)
      .format('YYYY-MM-DD');
    setNewLicense(updatedLicense);
    setHasChange(true);
  };

  const handleCancel = () => {
    setNewLicense({
      ...EMPTY_LICENSE,
    });
    setHasChange(false);
  };

  useEffect(() => {
    if (!show) {
      setNewLicense({
        ...EMPTY_LICENSE,
      });
    }
  }, [show]);

  const handleEditLicense = (orgId, type, newDate) => {
    const status = dayjs.utc(newDate) > dayjs.utc() ? 'active' : 'expired';

    ApiServiceServerless.post(`/licenses/${orgId}`, {
      expiration: dayjs.utc(newDate).format('YYYY-MM-DD'),
      license_type: type,
    })

      .then(() => {
        setIsSaving(false);
        licenseTableSetter(orgId, type, newDate, status);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const handleAddLicense = (orgId, type, newDate) => {
    const status = dayjs.utc(newDate) > dayjs.utc() ? 'active' : 'expired';

    ApiServiceServerless.put(`/licenses/${orgId}`, {
      expiration: dayjs.utc(newDate).format('YYYY-MM-DD'),
      license_type: type,
    })
      .then(() => {
        setIsSaving(false);
        licenseTableSetter(orgId, type, newDate, status);
      })
      .catch((err) => {
        setIsSaving(false);
        throw err;
      });
  };

  const handleSubmit = (e) => {
    setIsSaving(true);
    e.preventDefault();
    Object.keys(newLicense['licenses']).forEach((type) => {
      if (
        newLicense['licenses'][type]['expiration'] !==
        currentLicense['licenses'][type]['expiration']
      ) {
        if (currentLicense['licenses'][type]['expiration'] === '') {
          handleAddLicense(
            newLicense['id'],
            type,
            newLicense['licenses'][type]['expiration']
          );
        } else {
          handleEditLicense(
            newLicense['id'],
            type,
            newLicense['licenses'][type]['expiration']
          );
        }
      }
    });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>Edit Licenses</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body style={{ maxHeight: '600px', overflowY: 'scroll' }}>
          {newLicense.has_billing_contact === false && (
            <Row style={{ justifyContent: 'center' }}>
              <Alert style={{ width: '90%' }} variant='danger'>
                This organization does not have a billing contact. Please add a
                billing contact on the organization settings page to ensure
                billing can be processed.{' '}
              </Alert>
            </Row>
          )}
          <Row>
            <Col sm={12}>
              <Form.Group>
                <Form.Label>Organization</Form.Label>
                <Form.Control
                  type='text'
                  value={newLicense['name']}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>

          {['analysis', 'urjanet', 'plug', 'ghg', 'solar'].map((type) => (
            <>
              {type in newLicense['licenses'] && (
                <Row key={type}>
                  <Col>
                    <LicenseDatePicker
                      type={type}
                      date={newLicense['licenses'][type]['expiration']}
                      status={newLicense['licenses'][type]['status']}
                      handleChange={handleDateChange}
                    />
                  </Col>
                </Row>
              )}
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <EditEntityActionButtons
            onSubmit={handleSubmit}
            onDelete={handleCancel}
            onCancel={onHide}
            deleteDisabled={!hasChange}
            submitDisabled={!hasChange}
            deleteText={'Clear Changes'}
            isSubmitting={isSaving}
          />
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
