import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';

import { ApiServiceServerless, forceAuthRefreshAsync } from '../../../xhr_libs';
import ResourceDetails from '../../../components/ResourceDetails';
import ResourceActionButtons from '../ResourceActionButton';
import OrganizationTypes from '../../../helpers/OrganizationTypes';
import ConfirmationModal, {
  useConfirm,
} from '../../../components/modals/ConfirmationModal';
import {
  useOrganizationRoles,
  useOrganizationRolesWeight,
} from '../../../helpers/checkRoles';
import EditOrganizationModal from '../../../components/modals/ResourceModals/EditOrganizationModal';
import OrganizationPortfolios from './OrganizationPortfolios';
import OrganizationProviders from './OrganizationProviders';
import OrganizationContacts from './OrganizationContacts';
import UserManagement from '../../../components/UserManagement';
import OrganizationBuildingAccounts from './OrganizationBuildingAccounts';

export default function Organization(props) {
  const {
    organization_id,
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    selectedResource,
    setSelectedResource,
    organization,
    setOrganization,
    buildingInfo,
    setBuildingInfo,
    setUtilityAccountInfo,
    OBAUpdate,
    OPAUpdate,
    handleUpdateTables,
    OBADateUpdates,
    setOBADateUpdates,
    isLoading,
    setIsLoading,
    isSaving,
    setIsSaving,
    isDeleting,
    setIsDeleting,
    showToast,
  } = props;

  const updateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization',
    'update'
  );

  const deleteAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization',
    'delete'
  );
  const organizationUserCreateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization_user',
    'create'
  );
  const organizationUserUpdateAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization_user',
    'update'
  );
  const organizationUserDeleteAccess = useOrganizationRoles(
    userSelectedOrganizationDetails,
    'organization_user',
    'delete'
  );

  const organizationRoleWeight = useOrganizationRolesWeight(
    userSelectedOrganizationDetails
  );

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [showEditOrganizationModal, setShowEditOrganizationModal] =
    useState(false);

  const [activeTab, setActiveTab] = useState('organization-accounts');
  const [activeTabList, setActiveTabList] = useState(['organization-accounts']);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  useEffect(() => {
    addNewActiveTab(activeTab);
  }, [activeTab, addNewActiveTab]);

  const getOrganization = useCallback(() => {
    const id = parseInt(organization_id);
    if (id) {
      setIsLoading(true);
      ApiServiceServerless.get(`/organizations/${id}`, {
        authorization_id: id,
      })
        .then((res) => {
          let org = { ...res.data };
          setOrganization({ ...org });
        })
        .catch((error) => {
          showToast('danger', 'Error', error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [organization_id, setOrganization, setIsLoading, showToast]);

  useEffect(() => {
    getOrganization();
  }, [getOrganization]);

  const onDeleteClick = () => {
    let msg = `Are you sure you want to delete the Organization "${organization.name}"? This cannot be undone.`;

    const has_provider_account = organization['has_provider_account'];
    if (has_provider_account) {
      msg +=
        ' Additionally, any Automated Billing accounts associated with this organization will be disabled';
    }

    const beginOrganizationDelete = async () => {
      setIsDeleting(true);
      if (has_provider_account) {
        disableProviderAccounts();
      } else {
        await deleteOrganization();
      }
    };
    withConfirm(msg, beginOrganizationDelete);
  };

  const disableProviderAccounts = () => {
    ApiServiceServerless.post(
      `/providers/disable/organization/${organization_id}`,
      {},
      { authorization_id: organization_id }
    )
      .then(async () => {
        await deleteOrganization();
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        setIsDeleting(false);
        return false;
      });

    return true;
  };

  const deleteOrganization = async () => {
    return ApiServiceServerless.delete(`/organizations/${organization_id}`, {
      authorization_id: organization_id,
    })
      .then(async () => {
        await forceAuthRefreshAsync();
        showToast(
          'success',
          'Success',
          `Organization "${organization.name}" was deleted.`
        );
        setIsDeleting(false);
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        setIsDeleting(false);
      });
  };

  return (
    <div
      style={{
        display: selectedResource === 'organization' ? '' : 'none',
      }}
    >
      {!isLoading && organization.id && (
        <>
          <Row style={{ marginBottom: '1em' }}>
            <Col></Col>
            <Col md={8}>
              <Row>
                <Col md={3}>
                  <ResourceDetails
                    header={'Address'}
                    value={organization.address}
                    variant={'address'}
                  />
                </Col>
                <Col md={3}>
                  <ResourceDetails
                    header={'Organization Type'}
                    value={
                      organization.organization_type
                        ? OrganizationTypes[organization.organization_type]
                        : null
                    }
                  />
                </Col>
                <ResourceActionButtons
                  resource_name='Organization'
                  updateAccess={updateAccess}
                  deleteAccess={deleteAccess}
                  showEditModal={() => setShowEditOrganizationModal(true)}
                  handleDelete={() => onDeleteClick()}
                  disabled={isSaving || isDeleting}
                />
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Tabs
                id='organization-tabs'
                activeKey={activeTab}
                onSelect={(e) => addNewActiveTab(e)}
                className='et-main-tabs'
              >
                <Tab eventKey='organization-accounts' title='Accounts'>
                  <OrganizationBuildingAccounts
                    organizationId={organization_id}
                    userSelectedOrganization={userSelectedOrganization}
                    userSelectedOrganizationDetails={
                      userSelectedOrganizationDetails
                    }
                    buildingInfo={buildingInfo}
                    setSelectedResource={setSelectedResource}
                    setBuildingInfo={setBuildingInfo}
                    setUtilityAccountInfo={setUtilityAccountInfo}
                    OBAUpdate={OBAUpdate}
                    handleUpdateTables={handleUpdateTables}
                    OBADateUpdates={OBADateUpdates}
                    setOBADateUpdates={setOBADateUpdates}
                    visible={
                      activeTab === 'organization-accounts' &&
                      selectedResource === 'organization'
                    }
                    showToast={showToast}
                  />
                </Tab>

                <Tab eventKey='user-management' title='Users'>
                  {activeTabList.includes('user-management') && (
                    <UserManagement
                      entityType={'organization'}
                      entityId={parseInt(organization.id)}
                      showToast={showToast}
                      createAccess={organizationUserCreateAccess}
                      updateAccess={organizationUserUpdateAccess}
                      deleteAccess={organizationUserDeleteAccess}
                      roleWeight={organizationRoleWeight}
                    />
                  )}
                </Tab>
                <Tab eventKey='contact-management' title='Contacts'>
                  {activeTabList.includes('contact-management') && (
                    <OrganizationContacts
                      organization={organization}
                      userSelectedOrganizationDetails={
                        userSelectedOrganizationDetails
                      }
                      contacts={organization.contacts}
                      isLoading={isLoading}
                      showToast={showToast}
                      setOrganization={setOrganization}
                    />
                  )}
                </Tab>
                <Tab eventKey='portfolio-management' title='Portfolios'>
                  {activeTabList.includes('portfolio-management') && (
                    <OrganizationPortfolios
                      organization={organization}
                      userSelectedOrganizationDetails={
                        userSelectedOrganizationDetails
                      }
                      showToast={showToast}
                    />
                  )}
                </Tab>

                <Tab eventKey='data-providers' title='Data Providers'>
                  {activeTabList.includes('data-providers') && (
                    <OrganizationProviders
                      organization={organization}
                      userSelectedOrganizationDetails={
                        userSelectedOrganizationDetails
                      }
                      showToast={showToast}
                      OPAUpdate={OPAUpdate}
                      visible={
                        activeTab === 'data-providers' &&
                        selectedResource === 'organization'
                      }
                      handleUpdateTables={handleUpdateTables}
                    />
                  )}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </>
      )}
      <ConfirmationModal {...confirmationModalProps} />
      <EditOrganizationModal
        show={showEditOrganizationModal}
        onHide={() => {
          setShowEditOrganizationModal(false);
        }}
        organization={organization}
        setOrganization={setOrganization}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        showToast={showToast}
      />
    </div>
  );
}
