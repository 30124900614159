import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import MONTHSABBREVIATED from '../../../../helpers/monthsAbbreviated';
import EnergyTracerTable from '../../../tables';
import { CHARTCOLORS_RGB } from '../../../../helpers/chartColors';

const styles = {
  leftColumn: { padding: '0px 20px', verticalAlign: 'middle' },
  cellColumn: {
    padding: '0px',
    verticalAlign: 'middle',
  },
};

const factor_defaults = { scale: 1, constant: 0 };

export default function RegressionEventTable(props) {
  const { table_type, events, setLocalEvents, handleSave, years, disabled } =
    props;

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let temp_data = {};
    years.forEach((year) => {
      let temp_obj = { year: year };
      MONTHSABBREVIATED.forEach((month, idx) => {
        temp_obj[idx.toString()] = factor_defaults[table_type];
      });
      temp_data[year] = temp_obj;
    });
    Object.values(events).forEach((event) => {
      temp_data[event.year][event.month.toString()] = event.value;
    });

    setTableData(Object.values(temp_data));
  }, [events, years, table_type]);

  const updateData = (month, year, value) => {
    const key = year + '_' + month;
    setLocalEvents((prev) => ({
      ...prev,
      [key]: {
        value: parseFloat(value),
        month: month,
        year: year,
      },
    }));
  };

  const tableColumns = [].concat(
    [{ dataField: 'year', text: 'Year', style: styles.leftColumn }],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => ({
      dataField: month.toString(),
      text: MONTHSABBREVIATED[month],
      style: styles.cellColumn,
      formatter: (cell, row) => (
        <>
          <TableInput
            month={month}
            value={row[month]}
            year={row.year}
            table_type={table_type}
            default_value={
              Object.keys(events).includes(getKey(row.year, month))
                ? events[getKey(row.year, month)].value
                : factor_defaults[table_type]
            }
            updateData={updateData}
            disabled={disabled}
          />
        </>
      ),
    }))
  );

  return (
    <>
      <div className='et-regression-event-table-cont'>
        <EnergyTracerTable
          data={tableData}
          columns={tableColumns}
          keyField={'year'}
        />
        <Button
          type='submit'
          variant='primary'
          onClick={() => handleSave()}
          disabled={disabled}
        >
          Save
        </Button>
      </div>
    </>
  );
}

const TableInput = (props) => {
  const {
    month,
    value,
    year,
    table_type,
    default_value,
    updateData,
    disabled,
  } = props;

  const [color, setColor] = useState([255, 255, 255]);
  const [RGBA, setRGBA] = useState('rgba(255,255,255,0.75)');

  useEffect(() => {
    checkChangeColor(
      value,
      default_value,
      factor_defaults[table_type],
      setColor
    );
  }, [table_type, value, default_value]);

  useEffect(() => {
    let str = 'rgba(';
    color.forEach((col) => (str = str + col + ','));
    str = str + '0.75)';
    setRGBA(str);
  }, [color]);

  return (
    <Form style={{ height: '45px', borderLeft: '1px solid #ced4da' }}>
      <NumberFormat
        style={{
          backgroundColor: RGBA,
          textAlign: 'center',
          border: 'none',
          boxShadow: 'none',
          height: '100%',
          borderRadius: '0',
        }}
        customInput={Form.Control}
        thousandSeparator
        isNumericString
        fixedDecimalScale
        defaultValue={value}
        onChange={(e) => {
          updateData(month, year, e.target.value);
          checkChangeColor(
            e.target.value,
            default_value,
            factor_defaults[table_type],
            setColor
          );
        }}
        disabled={disabled}
      />
    </Form>
  );
};

const checkChangeColor = (value, default_value, factor_default, setColor) => {
  const localValue = parseFloat(value);
  if (localValue === default_value && localValue === factor_default) {
    setColor([255, 255, 255]);
  } else if (localValue === default_value && localValue !== factor_default) {
    setColor(CHARTCOLORS_RGB[0]);
  } else if (localValue !== default_value || localValue !== factor_default) {
    setColor(CHARTCOLORS_RGB[3]);
  }
};

const getKey = (year, month) => {
  return year + '_' + month;
};
