import React from 'react';
import { Row } from 'react-bootstrap';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';

import CHARTCOLORS from '../../helpers/chartColors';

const styles = {
  row: { height: 325 },
};

class WidgetPieHover extends React.Component {
  render() {
    const { title, dataseries, unit } = this.props;
    return (
      <Row style={styles.row}>
        <h5 style={{ textAlign: 'center', width: '100%' }}>{title}</h5>
        <ResponsiveContainer width='100%' debounce={dataseries.length}>
          <PieChart>
            <Tooltip
              formatter={(value) =>
                value.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }) +
                ' ' +
                unit
              }
            />
            <Pie
              data={dataseries}
              dataKey='value'
              nameKey='name'
              fill='#8884d8'
              legendType='circle'
              minAngle={4}
            >
              {dataseries.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={CHARTCOLORS[index % CHARTCOLORS.length]}
                />
              ))}
            </Pie>
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Row>
    );
  }
}

export default WidgetPieHover;
