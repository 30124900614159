import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Button, Row, Col, Form } from 'react-bootstrap';

import { ApiServiceServerless } from '../../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../modals/ConfirmationModal';

import AddRegressionEventCollectionModal from '../../../modals/AddRegressionEventCollectionModal';
import CloneRegressionEventCollectionModal from '../../../modals/CloneRegressionEventCollectionModal';
import EditRegressionEventCollectionModal from '../../../modals/EditRegressionEventCollectionModal';
import RegressionEventTable from './RegressionEventTable';
import RegressionEventRegressionsTable from './RegressionEventRegressionsTable';
import dayjs from 'dayjs';

const factor_defaults = { scale: 1, constant: 0 };

export default function RegressionEventCollections(props) {
  const [deferredProps, setDeferredProps] = useState({ ...props });
  useEffect(() => {
    if (props.open) setDeferredProps({ ...props });
  }, [props]);
  const {
    organization_id,
    createAccessAnalysis,
    regressionEventCollections,
    regressionEventYears,
    updateList,
    addToList,
    removeFromList,
    showToast,
    updateAccess,
    deleteAccess,
    buildingsLookup,
    existingModels,
    setRegressionEventCollectionsEditted,
  } = deferredProps;

  const [activeTab, setActiveTab] = useState('scale');

  const [activeCollectionId, setActiveCollectionId] = useState(null);
  const [activeCollection, setActiveCollection] = useState(null);

  const [scaleEvents, setScaleEvents] = useState({});
  const [constantEvents, setConstantEvents] = useState({});

  const [localScaleEvents, setLocalScaleEvents] = useState([]);
  const [localConstantEvents, setLocalConstantEvents] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [confirmationModalProps, withConfirm] = useConfirm();
  const [confirmationModalProps2, withConfirm2] = useConfirm();

  const [disabled, setDisabled] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const resetCollection = () => {
    setActiveCollectionId(null);
  };

  useEffect(() => {
    if (activeCollectionId !== null) {
      let collection = regressionEventCollections.find(
        (collection) => collection.id === activeCollectionId
      );
      setActiveCollection(collection ? collection : null);
    } else {
      setActiveCollection(null);
    }
  }, [activeCollectionId, regressionEventCollections]);

  useEffect(() => {
    if (activeCollectionId !== null && activeCollection !== null) {
      setScaleEvents(activeCollection.scale_events);
      setConstantEvents(activeCollection.constant_events);
    }
  }, [activeCollection, activeCollectionId]);

  const handleDelete = () => {
    const msg = `Are you sure you want to delete this Collection? This cannot be undone.`;

    withConfirm(msg, () => {
      deleteCustomGHGCollections();
    });
  };

  const deleteCustomGHGCollections = () => {
    setIsDeleting(true);
    ApiServiceServerless.delete(
      `/regression_events/collection/${activeCollectionId}`,
      { authorization_id: organization_id }
    )
      .then(() => {
        showToast('success', 'Success', 'Collection Deleted');
        removeFromList(activeCollectionId, 'regression_event_collections');
        resetCollection();
        setRegressionEventCollectionsEditted((prev) => prev + 1);
        setIsDeleting(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setIsDeleting(false);
        throw err;
      });
  };

  const checkEventRanges = () => {
    let check = false;
    let checkKeys = {};
    const regression_ids = activeCollection.regression_ids;
    const eventTypes = ['scale', 'constant'];

    eventTypes.forEach((type) => {
      let localEvents =
        type === 'scale' ? localScaleEvents : localConstantEvents;
      Object.values(localEvents).forEach((event) => {
        let event_date = dayjs.utc(new Date(event.year, event.month, 15));
        existingModels.forEach((model) => {
          if (regression_ids.includes(model.id)) {
            let model_start = dayjs.utc(model.start_date);
            let model_end = dayjs.utc(model.end_date);
            if (event_date > model_start && event_date < model_end) {
              check = true;
              checkKeys[model.id] = true;
            }
          }
        });
      });
    });
    checkKeys = Object.keys(checkKeys).map((key) => parseInt(key));
    return [check, checkKeys];
  };

  const handleSave = () => {
    const check = checkEventRanges();
    if (check[0]) {
      const msg = `Warning! The range you are editting falls within regression date ranges. If you save these change these regression will become invalid and will be removed from their projects`;

      withConfirm2(
        msg,
        () => {
          saveEvents(check[1]);
        },
        () => {},
        'Save'
      );
    } else {
      saveEvents([]);
    }
  };

  const saveEvents = (checkKeys) => {
    setDisabled(true);
    const sendData = [];

    ['scale', 'constant'].forEach((type) => {
      let localEvents =
        type === 'scale' ? localScaleEvents : localConstantEvents;
      let events = type === 'scale' ? scaleEvents : constantEvents;
      let existingKeys = Object.keys(events);
      Object.keys(localEvents).forEach((key) => {
        let temp_data = localEvents[key];
        temp_data['factor_type'] = type;
        if (
          existingKeys.includes(key) &&
          events[key].value !== localEvents.value &&
          localEvents.value !== factor_defaults[type]
        ) {
          temp_data['edit_type'] = 'update';
          temp_data['id'] = events[key]['id'];
          sendData.push(temp_data);
        } else if (
          existingKeys.includes(key) &&
          events[key].value !== localEvents.value &&
          localEvents.value === factor_defaults[type]
        ) {
          temp_data['edit_type'] = 'delete';
          temp_data['id'] = events[key]['id'];
          sendData.push(temp_data);
        } else if (
          !existingKeys.includes(key) &&
          localEvents.value !== factor_defaults[type]
        ) {
          temp_data['edit_type'] = 'new';
          sendData.push(temp_data);
        }
      });
    });
    ApiServiceServerless.put(
      `/regression_events/${activeCollectionId}`,
      {
        events: sendData,
        cancelRegressions: checkKeys,
      },
      { authorization_id: organization_id }
    )
      .then((res) => {
        showToast('success', 'Success', 'Events Updated');
        updateList(res.data[0], 'regression_event_collections');
        res.data[1].forEach((model) => {
          updateList(model, 'models');
        });
        setRegressionEventCollectionsEditted((prev) => prev + 1);
        setDisabled(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setDisabled(false);
      });
  };

  return (
    <>
      <Row>
        <Col style={{ display: 'inline-flex' }}>
          <Row>
            <Form inline style={{ marginBottom: '0.5em' }}>
              <Form.Label
                style={{
                  display: 'block',
                  marginRight: '0.5em',
                  marginLeft: '0.5em',
                }}
              >
                Collection:
              </Form.Label>
              <Form.Control
                style={{ float: 'right' }}
                as='select'
                size='sm'
                value={activeCollectionId ? activeCollectionId : ''}
                onChange={(e) => {
                  setActiveCollectionId(parseInt(e.target.value));
                }}
              >
                <option hidden value={'null'} key={'null-opt'}>
                  Select a Collection
                </option>
                {regressionEventCollections.length === 0 && (
                  <option disabled value={'dis'} key={'null-dis'}>
                    No Collection
                  </option>
                )}
                {regressionEventCollections.map((collection) => (
                  <option
                    key={`collection-opt-${collection.id}`}
                    value={collection.id}
                  >
                    {collection.name}
                  </option>
                ))}
              </Form.Control>
            </Form>
          </Row>
          <Row style={{ marginLeft: '20px' }}>
            {createAccessAnalysis && (
              <Button
                onClick={() => {
                  setShowAddModal(true);
                }}
                style={{
                  marginBottom: '1em',
                  marginLeft: '1em',
                }}
              >
                Add Collection
              </Button>
            )}
            {createAccessAnalysis && regressionEventCollections.length > 0 && (
              <Button
                onClick={() => {
                  setShowCloneModal(true);
                }}
                style={{ marginBottom: '1em', marginLeft: '1em' }}
              >
                Clone Collection
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      {activeCollection && (
        <h4 style={{ marginTop: '1em' }}>
          {activeCollection.name}{' '}
          {updateAccess && (
            <Button
              variant='edit'
              style={{ marginRight: '0.5em', marginLeft: '0.5em' }}
              onClick={() => setShowEditModal(true)}
              disabled={isDeleting}
            >
              Edit Collection
            </Button>
          )}
          {deleteAccess && (
            <Button
              variant='danger'
              style={{ marginRight: '0.5em', marginLeft: '0.5em' }}
              onClick={() => handleDelete()}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete Collection'}
            </Button>
          )}
        </h4>
      )}
      {activeCollectionId !== null && activeCollection !== null && (
        <Tabs
          id='regression-event-collection-tabs'
          activeKey={activeTab}
          onSelect={setActiveTab}
          className='et-second-tabs'
        >
          <Tab eventKey={'scale'} title='Scale'>
            <RegressionEventTable
              table_type='scale'
              events={scaleEvents}
              localScaleEvents={localScaleEvents}
              setLocalEvents={setLocalScaleEvents}
              handleSave={handleSave}
              years={regressionEventYears}
              disabled={disabled}
            />
          </Tab>
          <Tab eventKey={'constant'} title='Constant'>
            <RegressionEventTable
              organization_id={organization_id}
              collection_id={activeCollectionId}
              table_type='constant'
              events={constantEvents}
              localEvents={localConstantEvents}
              setLocalEvents={setLocalConstantEvents}
              handleSave={handleSave}
              years={regressionEventYears}
              disabled={disabled}
            />
          </Tab>
          <Tab eventKey={'regressions'} title='Regressions'>
            <RegressionEventRegressionsTable
              regressionEventCollection={activeCollection}
              buildingsLookup={buildingsLookup}
              existingModels={existingModels}
            />
          </Tab>
        </Tabs>
      )}
      <AddRegressionEventCollectionModal
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
        }}
        showToast={showToast}
        organization_id={organization_id}
        addToList={addToList}
        setActiveCollectionId={setActiveCollectionId}
        setRegressionEventCollectionsEditted={
          setRegressionEventCollectionsEditted
        }
      />
      <EditRegressionEventCollectionModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        showToast={showToast}
        organization_id={organization_id}
        collection={activeCollection}
        updateList={updateList}
        setRegressionEventCollectionsEditted={
          setRegressionEventCollectionsEditted
        }
      />
      <CloneRegressionEventCollectionModal
        show={showCloneModal}
        onHide={() => {
          setShowCloneModal(false);
        }}
        showToast={showToast}
        organization_id={organization_id}
        collections={regressionEventCollections}
        setActiveCollectionId={setActiveCollectionId}
        addToList={addToList}
        setRegressionEventCollectionsEditted={
          setRegressionEventCollectionsEditted
        }
      />
      <ConfirmationModal {...confirmationModalProps} />
      <ConfirmationModal {...confirmationModalProps2} />
    </>
  );
}
