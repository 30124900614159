import React from 'react';
import { Row } from 'react-bootstrap';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import CHARTCOLORS from '../../helpers/chartColors';

const styles = {
  row: { height: 350 },
};

class WidgetActiveBar extends React.Component {
  render() {
    const { dataseries, unit } = this.props;
    return (
      <Row style={styles.row}>
        <ResponsiveContainer width='100%' debounce={300}>
          <BarChart data={dataseries}>
            <YAxis
              type='number'
              tickFormatter={(tick) => {
                return tick.toLocaleString();
              }}
            />
            <XAxis type='category' dataKey='name' tick={false}>
              {' '}
            </XAxis>
            <Tooltip />
            <Bar
              dataKey='value'
              fill={CHARTCOLORS[1]}
              barSize={30}
              unit={' ' + unit}
              formatter={(value) =>
                value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }
            >
              {dataseries.map((_entry, index) => (
                <Cell
                  fill={CHARTCOLORS[index % CHARTCOLORS.length]}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Row>
    );
  }
}

export default WidgetActiveBar;
