import React, { useState, useEffect } from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';

import { ApiServiceServerless } from '../../xhr_libs';
import EnergyTracerTable from '../../components/tables';
import BuildingSelectModal from '../../components/modals/BuildingSelectModal';
import getIntegrityPercent from './BillIntergrityHelpers';
import CHARTCOLORS from '../../helpers/chartColors';
import Loader from '../../components/Loader';
import NavigationAlert from '../../components/analysis/NavigationAlert';

const styles = {
  column: {
    paddingTop: '10px',
  },
};

const cellFormatter = (val) => {
  if (isNaN(val)) {
    return <div style={{ color: 'black' }}>{'-'}</div>;
  }
  let print_val = (val * 100).toFixed(0) + '%';
  if (val > 0.95) {
    return <div style={{ color: 'green' }}>{print_val}</div>;
  } else if (val > 0.66) {
    return <div style={{ color: 'orange' }}>{print_val}</div>;
  } else {
    return <div style={{ color: 'red' }}>{print_val}</div>;
  }
};

const tableColumns = [
  {
    dataField: 'year',
    text: 'Year',
    style: styles.column,
  },
  {
    dataField: 'month1',
    text: 'Jan',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month2',
    text: 'Feb',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month3',
    text: 'Mar',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month4',
    text: 'Apr',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month5',
    text: 'May',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month6',
    text: 'Jun',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month7',
    text: 'Jul',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month8',
    text: 'Aug',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month9',
    text: 'Sep',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month10',
    text: 'Oct',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month11',
    text: 'Nov',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
  {
    dataField: 'month12',
    text: 'Dec',
    style: styles.column,
    formatter: (cell) => cellFormatter(cell),
  },
];

const default_found = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  11: 0,
  12: 0,
};

export default function BillIntegrityDetails(props) {
  const {
    org_id,
    buildingLookup,
    accountLookup,
    utilityTypes,
    years,
    isLoading,
    setIsLoading,
  } = props;

  const [found, setFound] = useState({});
  const [rows, setRows] = useState([]);
  const [localTotal, setLocalTotal] = useState({});

  const [utilityType, setUtilityType] = useState(null);
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [alertBuildings, setAlertBuildings] = useState([]);

  const [showBuildingModal, setShowBuildingModal] = useState(false);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [nonSelectedBuildings, setNonSelectedBuildings] = useState([]);
  const [availableBuildings, setAvailableBuildings] = useState([]);

  const getIntegrityAlerts = () => {
    setRows([]);
    setFound({});
    setIsLoading(true);
    ApiServiceServerless.post(
      `/alerts/integrity/${org_id}`,
      {
        utility: utilityType !== 'production' ? utilityType : utilityTypes[0],
        start_year: startYear,
        end_year: endYear,
        ignore_building_ids: nonSelectedBuildings,
      },
      { authorization_id: org_id }
    )
      .then((res) => {
        setAlertBuildings(selectedBuildings);
        setFound({
          found: res.data.found,
          found_count: res.data.found_count,
          found_modes: res.data.found_modes,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setUtilityType(null);
    setStartYear(null);
    setEndYear(null);
    setSelectedBuildings([]);
    setAvailableBuildings([]);
    setAlertBuildings([]);
  }, [org_id]);

  useEffect(() => {
    if (years !== null && years.length > 0) {
      if (years.length > 1) {
        setStartYear(years[years.length - 2]);
      } else {
        setStartYear(years[years.length - 1]);
      }
      setEndYear(years[years.length - 1]);
    } else {
      setStartYear(null);
      setEndYear(null);
    }
  }, [years]);

  useEffect(() => {
    let bids = Object.keys(buildingLookup).map((bid) => {
      return parseInt(bid);
    });

    setAvailableBuildings(bids);
    setSelectedBuildings(bids);
  }, [buildingLookup]);

  useEffect(() => {
    setLocalTotal({ percent: [], percent_count: [] });
    let local_total_percent = {};
    let local_total_percent_count = {};
    if (Object.keys(found).length > 0 && utilityType) {
      Object.keys(found['found']).forEach((building) => {
        if (alertBuildings.includes(parseInt(building))) {
          Object.keys(found['found'][building]).forEach((account) => {
            if (
              Object.keys(found['found'][building][account]).includes(
                utilityType
              )
            ) {
              let years = Object.keys(
                found['found'][building][account][utilityType]
              );
              years.forEach((year) => {
                if (!Object.keys(local_total_percent).includes(year)) {
                  local_total_percent[year] = { ...default_found };
                  local_total_percent_count[year] = {
                    ...default_found,
                  };
                }
                let months = Object.keys(
                  found['found'][building][account][utilityType][year]
                );
                months.forEach((month) => {
                  let local_found =
                    found['found'][building][account][utilityType][year][month];
                  let local_found_count =
                    found['found_count'][building][account][utilityType][year][
                      month
                    ];

                  let local_percent = getIntegrityPercent(
                    local_found,
                    local_found_count,
                    found['found_modes'][building][account][utilityType]
                  );
                  local_total_percent[year][month] += local_percent;
                  if (local_found_count !== -1) {
                    local_total_percent_count[year][month] += 1;
                  }
                });
              });
            }
          });
        }
      });

      setLocalTotal({
        percent: { ...local_total_percent },
        percent_count: { ...local_total_percent_count },
      });
    }
  }, [found, utilityType, alertBuildings]);

  useEffect(() => {
    let local_rows = [];
    if (Object.keys(found).length > 1 && utilityType) {
      Object.keys(found['found']).forEach((building) => {
        if (alertBuildings.includes(parseInt(building))) {
          Object.keys(found['found'][building]).forEach((account) => {
            if (
              Object.keys(accountLookup).includes(account) &&
              accountLookup[account].account_type === utilityType
            ) {
              local_rows.push([building, account, utilityType]);
            }
          });
        }
      });
      setRows(local_rows);
    } else {
      setRows([]);
    }
  }, [found, utilityType, accountLookup, alertBuildings]);

  const getTableData = (building, account, utility) => {
    const data = [];
    if (Object.keys(found['found'][building][account]).includes(utility)) {
      const found_base = found['found'][building][account][utility];
      const found_count = found['found_count'][building][account][utility];
      const found_modes = found['found_modes'][building][account][utility];

      const years = Object.keys(found_base);
      years.forEach((year) => {
        let local_data = {};
        local_data['year'] = year;
        let months = Object.keys(found_base[year]);
        months.forEach((month) => {
          let local_found = found_base[year][month];
          let local_found_count = found_count[year][month];
          let local_percent = getIntegrityPercent(
            local_found,
            local_found_count,
            found_modes
          );
          local_data['month' + month] = local_percent;
        });
        data.push(local_data);
      });
    }

    return data;
  };

  const getTotalTableData = (localTotal) => {
    const data = [];
    const years = Object.keys(localTotal['percent']);

    years.forEach((year) => {
      let local_data = {};
      local_data['year'] = year;
      let months = Object.keys(localTotal['percent'][year]);
      months.forEach((month) => {
        let local_percent = localTotal['percent'][year][month];
        let local_percent_count = localTotal['percent_count'][year][month];
        let local_total_percent =
          local_percent_count !== 0 ? local_percent / local_percent_count : 0;
        local_data['month' + month] = local_total_percent;
      });
      data.push(local_data);
    });
    return data;
  };

  return (
    <>
      <Form style={{ marginBottom: '1em' }}>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Utility Type:</Form.Label>
              <Form.Control
                as='select'
                size='sm'
                onChange={(e) => setUtilityType(e.target.value)}
                value={utilityType ? utilityType : ''}
              >
                <option key={`alert-utility-select`} value={'null'} hidden>
                  {'Select a Utility'}
                </option>
                {utilityTypes.length === 0 && (
                  <option key={`alert-utility-null`} value={'null'} disabled>
                    {'No available types'}
                  </option>
                )}
                {utilityTypes.map((utility) => (
                  <option key={`alert-utility-${utility}`} value={utility}>
                    {utility.charAt(0).toUpperCase() +
                      utility.substr(1).toLowerCase()}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Start Year:</Form.Label>
              <Form.Control
                as='select'
                size='sm'
                onChange={(e) => setStartYear(parseInt(e.target.value))}
                value={startYear ? startYear : ''}
              >
                {years.map((year) => (
                  <option key={`alert-start-year-${year}`} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>End Year:</Form.Label>
              <Form.Control
                as='select'
                size='sm'
                onChange={(e) => setEndYear(parseInt(e.target.value))}
                value={endYear ? endYear : ''}
              >
                {years.map((year) => (
                  <option key={`alert-end-year-${year}`} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Button
            variant='primary'
            onClick={() => getIntegrityAlerts()}
            disabled={
              !(utilityType !== null && endYear !== null && startYear !== null)
            }
          >
            Get Alerts
          </Button>
          <div style={{ marginLeft: '2em', paddingTop: '0.25em' }}>
            Select Buildings{' '}
            <FontAwesomeIcon
              style={{
                fontSize: '20px',
                marginLeft: '0.5em',
                color: CHARTCOLORS[1],
                cursor: 'pointer',
              }}
              icon={faEdit}
              id={`alerts-building-select-popover`}
              onClick={() => setShowBuildingModal(true)}
            />
          </div>
        </Form.Row>
      </Form>
      {isLoading ? <Loader /> : <></>}
      {found['found'] && Object.keys(found['found']).length === 0 ? (
        <NavigationAlert
          mainText={'No utility bills have been found.'}
          buttonText={'Check Bills'}
          location={'/resources'}
        />
      ) : (
        <></>
      )}
      {utilityType &&
        rows.length > 0 &&
        Object.keys(found).length > 0 &&
        Object.keys(accountLookup).length > 0 && (
          <>
            <br></br>

            <h4>{'Total'}</h4>
            <EnergyTracerTable
              data={getTotalTableData(localTotal)}
              columns={tableColumns}
              keyField={'year'}
            />
            <br></br>
            <br></br>

            <h3>
              <u>Building Breakdown</u>
            </h3>
            {rows.map((row) => (
              <div key={row[0] + ' - ' + row[1]}>
                <>
                  <h4>
                    {buildingLookup[row[0]]['name'] + ' - '}
                    <Link to='/resources' target='_blank'>
                      {' '}
                      {accountLookup[row[1]]['account_number']}
                    </Link>
                  </h4>
                  <EnergyTracerTable
                    data={getTableData(row[0], row[1], utilityType)}
                    columns={tableColumns}
                    keyField={'year'}
                  />
                  <br></br>
                  <br></br>
                </>
              </div>
            ))}
          </>
        )}

      <BuildingSelectModal
        show={showBuildingModal}
        onHide={() => setShowBuildingModal(false)}
        buildings={{
          ...Object.values(buildingLookup),
        }}
        buildingIds={availableBuildings}
        selectedBuildings={selectedBuildings}
        setSelectedBuildings={setSelectedBuildings}
        setNonSelectedBuildings={setNonSelectedBuildings}
      />
    </>
  );
}
