import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab, Container, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import Helmet from 'react-helmet';

import { ApiServiceServerless } from '../../xhr_libs';

import UtilitySummary from './UtilitySummary';
import HistoricalData from './HistoricalData';
import CarbonSummary from './CarbonSummary';

import { useTabUrl } from '../../helpers/tracked';
import DashboardDateSelectContainer from './DashboardDateSelectContainer';
import Loader from '../../components/Loader';

const PAGE_TITLE = 'Dashboard';

const defaultDashboardData = {
  building_info: {},
  benchmark_missing: [],
  building_totals: [],
  benchmark_data: {},
  infographics: {
    total_buildings: 0,
    total_area: 0,
    total_cost: 0,
    total_usage: 0,
    cost_eui: 0,
    usage_eui: 0,
  },
  organization_usage: {},
  month_array: [],
  ghg_breakdown: {},
};

function Dashboard(props) {
  const {
    userSelectedOrganization,
    userSelectedOrganizationDetails,
    setDisableOrganizationSelect,
    showToast,
  } = props;

  const [dashboardData, setDashboardData] = useState(defaultDashboardData);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateOption, setDateOption] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [activeTab, setActiveTab] = useTabUrl('utility-summary');
  const [activeTabList, setActiveTabList] = useState(['building-date']);

  useEffect(() => {
    setDisableOrganizationSelect(isLoading);
  }, [isLoading, setDisableOrganizationSelect]);

  const addNewActiveTab = useCallback(
    (tab) => {
      if (tab !== activeTab) {
        setActiveTab(tab);
      }
      if (!activeTabList.includes(tab)) {
        let temp_keys = [...activeTabList];
        temp_keys.push(tab);
        setActiveTabList(temp_keys);
      }
    },
    [activeTab, activeTabList, setActiveTab]
  );

  useEffect(() => {
    addNewActiveTab(activeTab);
  }, [activeTab, addNewActiveTab]);

  const [activeGHGLicense, setActiveGHGLicense] = useState(false);
  useEffect(() => {
    let tempGHGLicense = false;
    if (userSelectedOrganizationDetails.id) {
      if (
        userSelectedOrganizationDetails &&
        userSelectedOrganizationDetails.licenses &&
        userSelectedOrganizationDetails.licenses.ghg
      ) {
        tempGHGLicense =
          userSelectedOrganizationDetails.licenses.ghg.status === 'active';
      }
    }
    setActiveGHGLicense(tempGHGLicense);
  }, [userSelectedOrganizationDetails]);

  const getDashboardData = useCallback(
    (orgId, endDate, dateOption) => {
      setIsLoading(true);
      ApiServiceServerless.get(
        `/dashboard/${orgId}/${dayjs
          .utc(endDate)
          .format('YYYY-MM-DD')}/${dateOption}`,
        { authorization_id: orgId }
      )
        .then(({ data }) => {
          if (Object.keys(data).length !== 0) {
            setDashboardData(data);
          } else {
            setDashboardData(defaultDashboardData);
          }
        })
        .catch((err) => {
          showToast('danger', 'Error', err);
          throw err;
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setIsLoading, showToast]
  );

  useEffect(() => {
    if (userSelectedOrganization.id && endDate) {
      getDashboardData(userSelectedOrganization.id, endDate, dateOption);
    }
  }, [userSelectedOrganization.id, endDate, dateOption, getDashboardData]);

  const getEndDate = useCallback(
    (orgId) => {
      if (endDate) return endDate;
      setIsLoading(true);
      ApiServiceServerless.get(`/dashboard/${orgId}`, {
        authorization_id: orgId,
      })
        .then((res) => {
          let end_date = dayjs.utc();
          if (res.data) {
            end_date = dayjs.utc(res.data);
          }
          let start_date = end_date;
          start_date = start_date
            .month(start_date.month() - 11)
            .startOf('month');

          setEndDate(end_date);
          setStartDate(start_date);
          return end_date;
        })
        .catch((err) => {
          setIsLoading(false);
          throw err;
        });
    },
    [setIsLoading, endDate]
  );

  useEffect(() => {
    if (!userSelectedOrganization.id) {
      setDashboardData(defaultDashboardData);
      setEndDate();
      setIsLoading(false);
    } else {
      getEndDate(userSelectedOrganization.id);
    }
  }, [userSelectedOrganization.id, getEndDate]);

  return (
    <>
      <Container className='et-container et-main-tab-content'>
        <Helmet>
          <title>{PAGE_TITLE}</title>
        </Helmet>

        <Row
          style={{
            display: 'flex',
            float: 'right',
            padding: '0.5rem',
            width: '400px',
          }}
          className='et-dashboard-date-select-cont'
        >
          <DashboardDateSelectContainer
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            dateOption={dateOption}
            setDateOption={setDateOption}
            isLoading={isLoading}
          />
        </Row>
        <Tabs
          id='dashboard-tabs'
          activeKey={activeTab}
          onSelect={(e) => addNewActiveTab(e)}
          className='et-main-tabs'
        >
          <Tab eventKey='utility-summary' title={'Utility Summary'}>
            {activeTabList.includes('utility-summary') && (
              <>
                {' '}
                {isLoading ? (
                  <Loader />
                ) : (
                  <UtilitySummary
                    dashboardData={dashboardData}
                    organization={userSelectedOrganization}
                    open={activeTab === 'utility-summary'}
                  />
                )}
              </>
            )}
          </Tab>

          <Tab eventKey='historical-data' title={'Historical Data'}>
            {activeTabList.includes('historical-data') && (
              <>
                {' '}
                {isLoading ? (
                  <Loader />
                ) : (
                  <HistoricalData
                    dashboardData={dashboardData}
                    organization={userSelectedOrganization}
                    endDate={endDate}
                    dateOption={dateOption}
                    open={activeTab === 'historical-data'}
                    activeGHGLicense={activeGHGLicense}
                  />
                )}
              </>
            )}
          </Tab>

          <Tab eventKey='carbon-summary' title={'Carbon Summary'}>
            {activeTabList.includes('carbon-summary') && (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <CarbonSummary
                    isLoading={isLoading}
                    dashboardData={dashboardData}
                    organization={userSelectedOrganization}
                    endDate={endDate}
                    dateOption={dateOption}
                    activeGHGLicense={activeGHGLicense}
                    open={activeTab === 'carbon-summary'}
                  />
                )}
              </>
            )}
          </Tab>
        </Tabs>
      </Container>
    </>
  );
}

export default Dashboard;
