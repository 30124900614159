import axios from 'axios';

import {
  validToken,
  getAuthToken,
  setAuthToken,
  setJustAuthToken,
  forceLogout,
  checkFreshness,
  checkRoutes,
  setFreshness,
  getUserAuthRefreshFlag,
} from '../auth';

export const API_ENDPOINT_SERVERLESS =
  process.env.REACT_APP_API_ENDPOINT_SERVERLESS ||
  'https://dev.api.web.energytracer.com';

export const ApiServiceServerless = axios.create({
  baseURL: API_ENDPOINT_SERVERLESS,
  timeout: 150000,
});

export const ApiServicePublicServerless = axios.create({
  baseURL: API_ENDPOINT_SERVERLESS,
  timeout: 150000,
});

export const AuthRefresherServerless = axios.create({
  baseURL: API_ENDPOINT_SERVERLESS,
  timeout: 150000,
});

AuthRefresherServerless.interceptors.request.use(
  function (config) {
    const token = getAuthToken();
    return getConfig(config, token);
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiServiceServerless.interceptors.request.use(
  function (config) {
    if (!validToken()) forceLogout();
    const token = getAuthToken();

    if (!checkFreshness() && checkRoutes(config)) {
      setFreshness();
      AuthRefresherServerless.get(`/auth/refresh/${getUserAuthRefreshFlag()}`)
        .then((token_response) => {
          if (token_response['data']['user_claims'] !== null) {
            setAuthToken(token_response);
          } else {
            handleJustAuthToken(token_response);
          }
        })
        .catch((err) => {
          forceLogout();
          throw err;
        });
    }
    return getConfig(config, token);
  },
  function (error) {
    return Promise.reject(error);
  }
);

ApiServiceServerless.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    error.response = handleProcessCustomErrorResponse(error.response);
    return Promise.reject(error);
  }
);

ApiServicePublicServerless.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    error.response = handleProcessCustomErrorResponse(error.response);
    return Promise.reject(error);
  }
);

ApiServicePublicServerless.interceptors.request.use(
  function (config) {
    return getConfig(config, null);
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const handleProcessCustomErrorResponse = (response) => {
  response = handleArrayBufferResponse(response);
  if (
    response &&
    response.data &&
    response.status &&
    response.status !== 200 &&
    response.data.detail &&
    response.data.detail.custom_error &&
    response.data.detail.msg
  ) {
    let error = Error();
    error.response = {
      data: { detail: response.data.detail.msg },
      status: response.status,
      sentry: response.data.detail.sentry,
    };
    throw error;
  }
  return response;
};

export const handleArrayBufferResponse = (response) => {
  if (
    response &&
    response.request &&
    response.request.responseType === 'arraybuffer' &&
    response.data &&
    response.data.byteLength &&
    response.data.byteLength < 5000
  ) {
    const enc = new TextDecoder('utf-8');
    try {
      let temp = response.data;
      temp = enc.decode(temp);
      temp = JSON.parse(temp);
      response.data = temp;
    } catch (err) {
      console.log(err);
    }
  }
  return response;
};

export const forceAuthRefresh = () => {
  setFreshness();
  AuthRefresherServerless.get('/auth/refresh/0').then((token_response) => {
    setAuthToken(token_response);
  });
};

export const forceAuthRefreshAsync = async () => {
  setFreshness();
  return AuthRefresherServerless.get('/auth/refresh/0').then(
    (token_response) => {
      setAuthToken(token_response);
    }
  );
};

export const handleJustAuthToken = (token_response) => {
  setJustAuthToken(token_response['data']['access_token']);
};

const getConfig = (config, token) => {
  if (token) config.headers.common['Authorization'] = 'Bearer ' + token;
  config.headers.common['Access-Control-Allow-Origin'] = '*';
  config.headers.common['Accept'] = 'application/json';
  if (config.authorization_id)
    config.headers.common['authorization-id'] = config.authorization_id;
  if (config.alternate_authorization_id)
    config.headers.common['alternate-authorization-id'] =
      config.alternate_authorization_id;
  if (config.token_overwrite)
    config.headers.common['Authorization'] = 'Bearer ' + config.token_overwrite;
  return config;
};

export default ApiServiceServerless;
