import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import EnergyTracerTable from '../tables';
import { ROLES, ROLE_NAMES_UPPER, ROLE_WEIGHTS } from '../../helpers/RolesData';

const styles = {
  column: {
    padding: '22px 15px 0px 15px',
  },
  actionColumn: {
    padding: '16px 15px 0px 15px',
  },
};

const STATIC_COLUMNS = [
  {
    dataField: 'email',
    text: 'Email',
    style: styles.column,
  },
  {
    dataField: 'firstName',
    text: 'First Name',
    style: styles.column,
  },
  {
    dataField: 'lastName',
    text: 'Last Name',
    style: styles.column,
  },
];

export default function UserTable(props) {
  const {
    entityType,
    users,
    deleteUser,
    saveUserRoles,
    setUsers,
    addUserToken,
    deleteUserToken,
    updateAccess,
    deleteAccess,
    roleWeight,
  } = props;

  // Allows immediate UI feedback onChange. Value is corrected if an error occurs.
  const changeUserRole = (userId, userRole, oldRole) => {
    setUsers(
      users.map((user) => {
        if (user.id !== userId) return user;
        else {
          user.role = userRole;
          return user;
        }
      })
    );
    saveUserRoles(userId, userRole, oldRole);
  };

  const userColumns = [
    ...STATIC_COLUMNS,
    {
      dataField: 'role',
      text: 'Role',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <UserRoleSelect
          userId={row.id}
          userRole={cell}
          saveUserRoles={saveUserRoles}
          changeUserRole={changeUserRole}
          disabled={!updateAccess}
          roleWeight={roleWeight}
        />
      ),
    },
  ];

  const actionColumns = [
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell, row) =>
        roleWeight >= ROLE_WEIGHTS[row.role] ? (
          <UserActions userId={cell} deleteUser={deleteUser} />
        ) : (
          <></>
        ),
    },
  ];

  const tokenColumns = [
    {
      dataField: 'token',
      text: 'API Token',
      style: styles.actionColumn,
      formatter: (cell, row) => (
        <UserToken
          token={cell}
          userId={row['id']}
          addUserToken={addUserToken}
          deleteUserToken={deleteUserToken}
        />
      ),
    },
  ];

  let tableColumns = userColumns;
  tableColumns = [].concat(
    tableColumns,
    entityType === 'organization' ? tokenColumns : []
  );
  tableColumns = [].concat(tableColumns, deleteAccess ? actionColumns : []);

  return (
    <EnergyTracerTable
      data={users}
      columns={tableColumns}
      keyField={'id'}
      deleteUser={deleteUser}
      saveUserRoles={saveUserRoles}
    />
  );
}

UserTable.propTypes = {
  addUserToken: PropTypes.func,
  deleteUserToken: PropTypes.func,
};

UserTable.defaultProps = {
  addUserToken: () => {},
  deleteUserToken: () => {},
};

const UserRoleSelect = (props) => {
  const { userId, userRole, changeUserRole, disabled, roleWeight } = props;
  return (
    <Form.Group>
      <Form.Control
        data-id='user-role_select'
        as='select'
        value={userRole}
        disabled={disabled || roleWeight < ROLE_WEIGHTS[userRole]}
        onChange={(e) => {
          changeUserRole(userId, e.target.value, userRole);
        }}
      >
        {ROLES.map(
          (role) =>
            (roleWeight >= ROLE_WEIGHTS[role] || role === userRole) && (
              <option key={`user-${userId}-role-${role}`} value={role}>
                {ROLE_NAMES_UPPER[role]}
              </option>
            )
        )}
      </Form.Control>
    </Form.Group>
  );
};

const UserActions = (props) => {
  const { userId, deleteUser } = props;
  return (
    <Col>
      <button
        className='btn btn-danger'
        onClick={() => {
          deleteUser(userId);
        }}
      >
        <FontAwesomeIcon
          data-id='delete-user_btn'
          style={{ fontSize: '21px', lineHeight: 'inherit', paddingTop: '2px' }}
          icon={faTrashAlt}
        />
      </button>
    </Col>
  );
};

const UserToken = (props) => {
  const { token, userId, addUserToken, deleteUserToken } = props;
  return (
    <>
      {token === null ? (
        <Button
          variant='link'
          style={{ width: '100%', margin: '0px', padding: '0px' }}
          onClick={() => addUserToken(userId)}
        >
          <div style={{ textAlign: 'left', margin: 'auto' }}>
            <FontAwesomeIcon icon={faPlusSquare} style={{ fontSize: '23px' }} />
          </div>
        </Button>
      ) : (
        <div>
          {token}
          {'  '}
          <Button
            variant='outline-danger'
            style={{
              padding: '0px',
              borderWidth: '0px',
              borderRadius: '50%',
              height: '28px',
              width: '28px',
            }}
          >
            <FontAwesomeIcon
              style={{ fontSize: '18px', lineHeight: 'inherit' }}
              icon={faTimesCircle}
              onClick={() => deleteUserToken(userId)}
            />
          </Button>
        </div>
      )}
    </>
  );
};
