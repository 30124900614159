import React from 'react';
import { Card } from 'react-bootstrap';
import { Cell, Pie, PieChart, ResponsiveContainer, Legend } from 'recharts';

import CHARTCOLORS from '../../helpers/chartColors';
import formatValue from '../../helpers/chartFormatter';

const styles = {
  card: { height: 400 },
};

class WidgetPie extends React.Component {
  render() {
    const { title, dataseries } = this.props;
    return (
      <Card style={styles.card}>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <ResponsiveContainer width='100%' debounce={dataseries.length}>
            <PieChart>
              <Pie
                data={dataseries}
                dataKey='value'
                nameKey='name'
                fill='#8884d8'
                label={({ value }) => formatValue(value)}
                legendType='circle'
                minAngle={4}
              >
                {dataseries.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={CHARTCOLORS[index % CHARTCOLORS.length]}
                  />
                ))}
              </Pie>
              <Legend verticalAlign='top' />
            </PieChart>
          </ResponsiveContainer>
        </Card.Body>
      </Card>
    );
  }
}

export default WidgetPie;
