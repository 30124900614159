import React from 'react';
import { Nav, Dropdown, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import {
  faUser,
  faSignOut,
  faCity,
  faBell,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  iconStyles: { fontSize: '20px', verticalAlign: 'sub', float: 'right' },
};

export default function SettingsPopover() {
  const onClose = () => {
    document.getElementById('settings-popover-button').click();
  };

  return (
    <>
      <Dropdown style={{ width: '165px' }}>
        <Dropdown.Divider />
        <Dropdown.Item as={Col}>
          <Nav onClick={() => onClose()}>
            <LinkContainer to='/profile'>
              <Nav.Link
                className='et-nav-0'
                active={false}
                data-id='brand_profile'
              >
                <p className='d-inline mr-2'>Profile</p>
                <FontAwesomeIcon style={styles.iconStyles} icon={faUser} />
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Col}>
          <Nav onClick={() => onClose()}>
            <LinkContainer to='/portfolios'>
              <Nav.Link
                className='et-nav-0'
                active={false}
                data-id='brand_portfolios'
              >
                <p className='d-inline mr-2'>Portfolios</p>
                <FontAwesomeIcon style={styles.iconStyles} icon={faCity} />
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Col}>
          <Nav onClick={() => onClose()}>
            <LinkContainer to='/tools'>
              <Nav.Link
                className='et-nav-0'
                active={false}
                data-id='brand_tools'
              >
                <p className='d-inline mr-2'>Tools</p>
                <FontAwesomeIcon style={styles.iconStyles} icon={faWrench} />
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Col}>
          <Nav onClick={() => onClose()}>
            <LinkContainer to='/alerts'>
              <Nav.Link
                className='et-nav-0'
                active={false}
                data-id='brand_alerts'
              >
                <p className='d-inline mr-2'>Alerts</p>
                <FontAwesomeIcon style={styles.iconStyles} icon={faBell} />
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item as={Col}>
          <Nav>
            <LinkContainer to='/logout' data-id='nav_logout'>
              <Nav.Link className='et-nav-0' active={false} data-id='logout'>
                <p className='d-inline mr-2'>Sign Out</p>
                <FontAwesomeIcon style={styles.iconStyles} icon={faSignOut} />
              </Nav.Link>
            </LinkContainer>
          </Nav>
        </Dropdown.Item>
        <Dropdown.Divider />
      </Dropdown>
    </>
  );
}
