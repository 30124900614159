import React, { useEffect, useState } from 'react';
import { Button, Form, Col } from 'react-bootstrap';

import { ApiServiceServerless } from '../../xhr_libs';

const REPULL_OPTIONS = {
  credential: {
    option: 'Credential',
    name: 'Credential',
    name2: false,
    name3: false,
    url: '/plug/repull/credential',
  },
  account: {
    option: 'Account',
    name: 'Account',
    name2: false,
    name3: false,
    url: '/plug/repull/account',
  },
  meter: {
    option: 'Meter',
    name: 'Account',
    name2: 'Meter',
    name3: false,
    url: '/plug/repull/meter',
  },
  statement: {
    option: 'Statement',
    name: 'Account',
    name2: 'Statement',
    name3: false,
    url: '/plug/repull/statement',
  },
  bill: {
    option: 'Bill',
    name: 'Account',
    name2: 'Meter',
    name3: 'Statement',
    url: '/plug/repull/bill',
  },
};

const TRIM_OPTIONS = {
  organization: {
    option: 'Organization',
    url: '/plug/trim/organization',
  },
  credential: {
    option: 'Credential',
    url: '/plug/trim/credential',
  },
  account: {
    option: 'Account',
    url: '/plug/trim/account',
  },
};

const TRIM_TYPES = {
  urjanet: 'Urjanet',
  manual: 'Manual',
  both: 'Both',
};

export default function AdminPlugTools(props) {
  const { showToast, isGenerating, setIsGenerating } = props;

  const [repullOption, setRepullOption] = useState(null);
  const [repullId, setRepullId] = useState(null);
  const [repullId2, setRepullId2] = useState(null);
  const [repullId3, setRepullId3] = useState(null);

  const [trimOption, setTrimOption] = useState(null);
  const [trimId, setTrimId] = useState(null);
  const [trimType, setTrimType] = useState('urjanet');

  useEffect(() => {
    setRepullId(null);
    setRepullId2(null);
    setRepullId3(null);
  }, [repullOption]);

  const handleRepullResource = () => {
    setIsGenerating(true);
    let url = REPULL_OPTIONS[repullOption]['url'] + '/' + repullId;
    if (REPULL_OPTIONS[repullOption]['name2']) {
      url = url + '/' + repullId2;
    }
    if (REPULL_OPTIONS[repullOption]['name3']) {
      url = url + '/' + repullId3;
    }
    ApiServiceServerless.get(url)
      .then(() => {
        showToast(
          'success',
          'Success',
          'Repull in progress. Please check back in a few minutes'
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  const handleTrimResource = () => {
    setIsGenerating(true);
    let url = TRIM_OPTIONS[trimOption]['url'] + '/' + trimId + '/' + trimType;
    ApiServiceServerless.get(url)
      .then(() => {
        showToast(
          'success',
          'Success',
          'Trim in progress. Please check back in a few minutes'
        );
      })
      .catch((error) => {
        showToast('danger', 'Error', error);
        throw error;
      })
      .finally(() => {
        setIsGenerating(false);
      });
  };

  return (
    <>
      <br></br>
      <h3>Plug Tools</h3>
      <Form>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Repull Option</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setRepullOption(e.target.value);
              }}
              value={repullOption}
            >
              <option hidden key={'repull-select-option'} value={null}></option>
              {Object.keys(REPULL_OPTIONS).map((key) => (
                <option key={key} value={key}>
                  {REPULL_OPTIONS[key]['option']}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {repullOption && (
            <Form.Group as={Col}>
              <Form.Label>{REPULL_OPTIONS[repullOption]['name']} ID</Form.Label>
              <Form.Control
                value={repullId ? repullId : ''}
                onChange={(e) => setRepullId(e.target.value)}
              />
            </Form.Group>
          )}
          {repullOption && REPULL_OPTIONS[repullOption]['name2'] !== false && (
            <Form.Group as={Col}>
              <Form.Label>
                {repullOption && REPULL_OPTIONS[repullOption]['name2']} ID
              </Form.Label>
              <Form.Control
                value={repullId2 ? repullId2 : ''}
                onChange={(e) => setRepullId2(e.target.value)}
              />
            </Form.Group>
          )}
          {repullOption && REPULL_OPTIONS[repullOption]['name3'] !== false && (
            <Form.Group as={Col}>
              <Form.Label>
                {REPULL_OPTIONS[repullOption]['name3']} ID
              </Form.Label>
              <Form.Control
                value={repullId3 ? repullId3 : ''}
                onChange={(e) => setRepullId3(e.target.value)}
              />
            </Form.Group>
          )}
          <Form.Group as={Col} md={2}>
            <Form.Label>Action</Form.Label>
            <Form.Control
              as={Button}
              disabled={
                !repullOption ||
                !repullId ||
                (REPULL_OPTIONS[repullOption]['name2'] && !repullId2) ||
                (REPULL_OPTIONS[repullOption]['name3'] && !repullId3) ||
                isGenerating
              }
              onClick={() => {
                handleRepullResource();
              }}
            >
              Repull Resource
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
      <Form>
        <Form.Row>
          <Form.Group as={Col} md={3}>
            <Form.Label>Trim Type</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setTrimType(e.target.value);
              }}
              value={trimType}
            >
              {Object.keys(TRIM_TYPES).map((key) => (
                <option key={key} value={key}>
                  {TRIM_TYPES[key]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={3}>
            <Form.Label>Trim Option</Form.Label>
            <Form.Control
              as='select'
              onChange={(e) => {
                setTrimOption(e.target.value);
              }}
              value={trimOption}
            >
              <option hidden key={'trim-select-option'} value={null}></option>
              {Object.keys(TRIM_OPTIONS).map((key) => (
                <option key={key} value={key}>
                  {TRIM_OPTIONS[key]['option']}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {trimOption && (
            <Form.Group as={Col}>
              <Form.Label>{TRIM_OPTIONS[trimOption]['option']} ID</Form.Label>
              <Form.Control
                value={trimId ? trimId : ''}
                onChange={(e) => setTrimId(e.target.value)}
              />
            </Form.Group>
          )}

          <Form.Group as={Col} md={2}>
            <Form.Label>Action</Form.Label>
            <Form.Control
              as={Button}
              disabled={!trimOption || !trimId || isGenerating}
              onClick={() => {
                handleTrimResource();
              }}
            >
              Trim Resource
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
}
