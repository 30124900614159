import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

import { ApiServiceServerless } from '../../../../xhr_libs';
import ConfirmationModal, {
  useConfirm,
} from '../../../modals/ConfirmationModal';

import EnergyTracerTable from '../../../tables';
import ViewRateModal from '../../../modals/ViewRateModal';
import AddRateCollectionModal from '../../../modals/AddRateCollectionModal';
import AddOrganizationRateModal from './EditCustomRates/AddOrganizationRateModal';
import EditCustomRateCollectionModal from '../../../modals/EditCustomRateCollectionModal';
import MONTHSABBREVIATED from '../../../../helpers/monthsAbbreviated';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
  },
  actionColumn: {
    padding: '16px 15px 15px 15px',
  },
};

export default function CustomRateCollection(props) {
  const {
    showToast,
    organizationId,
    updateAccess,
    deleteAccess,
    rates,
    collection,
    addToList,
    updateList,
    removeFromList,
    resetCollection,
  } = props;

  const [tableData, setTableData] = useState([]);

  const [showAdd, setShowAdd] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [showView, setShowView] = useState(false);
  const [viewRow, setViewRow] = useState(null);

  const [confirmationModalProps, withConfirm] = useConfirm();

  const [isDeleting, setIsDeleting] = useState(false);

  const handleSetView = (row) => {
    setViewRow(row);
    setShowView(true);
  };

  const handleHideView = () => {
    setViewRow(null);
    setShowView(false);
  };

  useEffect(() => {
    let temp_data = [];
    if (rates.length > 0) {
      rates.forEach((rate) => {
        if (collection.rate_ids.includes(rate.id)) {
          temp_data.push(rate);
        }
      });
      setTableData(sortBy(temp_data, ['year', 'month']));
    } else {
      setTableData([]);
    }
  }, [collection, rates]);

  const handleDelete = () => {
    const msg = `Are you sure you want to delete this Collection? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteCustomRateCollections();
    });
  };

  const deleteCustomRateCollections = () => {
    setIsDeleting(true);
    ApiServiceServerless.delete(`/custom_rates/collection/${collection.id}`, {
      authorization_id: organizationId,
    })
      .then(() => {
        showToast('success', 'Success', 'Collection Deleted');
        removeFromList(collection.id, 'rate_collections');
        resetCollection();
        setIsDeleting(false);
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        setIsDeleting(false);
        throw err;
      });
  };

  const handleDeleteCollectionRates = (id) => {
    const msg = `Are you sure you want to delete this Rate from this Collection? This cannot be undone.`;
    withConfirm(msg, () => {
      deleteCustomRateCollectionRates(id);
    });
  };

  const deleteCustomRateCollectionRates = (id) => {
    ApiServiceServerless.delete(
      `/custom_rates/collection_rate/${collection.id}/${id}`,
      {
        authorization_id: organizationId,
      }
    )
      .then((res) => {
        showToast('success', 'Success', 'Collection Deleted');
        updateList(res.data[0], 'rate_collections');
        updateList(res.data[1], 'rates');
      })
      .catch((err) => {
        showToast('danger', 'Error', err);
        throw err;
      });
  };

  const tableColumns = [
    {
      dataField: 'name',
      text: 'Rate',
      style: styles.column,
      formatter: (cell, row) => (
        <>
          <div
            className='et-link'
            style={{ display: 'inline-block' }}
            onClick={() => {
              handleSetView(row);
            }}
          >
            {row.name}{' '}
          </div>
        </>
      ),
    },
    { dataField: 'year', text: 'Year', style: styles.column },
    {
      dataField: 'month',
      text: 'Month',
      style: styles.column,
      formatter: (cell, row) => <>{MONTHSABBREVIATED[row.month - 1]}</>,
    },
    {
      dataField: 'id',
      text: 'Actions',
      style: styles.actionColumn,
      formatter: (cell) => (
        <>
          {deleteAccess && (
            <Button
              className='btn btn-danger'
              style={{ maxWidth: '33px', maxHeight: '31px' }}
              onClick={() => {
                handleDeleteCollectionRates(cell);
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: '18px',
                  lineHeight: 'inherit',
                }}
                icon={faTrashAlt}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {collection && (
        <h4 style={{ marginTop: '1em' }}>
          {collection.name + ' (' + collection.utility_type + ')'}{' '}
          {updateAccess && (
            <Button
              variant='edit'
              style={{ marginRight: '0.5em', marginLeft: '0.5em' }}
              onClick={() => setShowEditModal(true)}
              disabled={isDeleting}
            >
              Edit Collection
            </Button>
          )}
          {deleteAccess && (
            <Button
              variant='danger'
              style={{ marginRight: '0.5em', marginLeft: '0.5em' }}
              onClick={() => handleDelete()}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete Collection'}
            </Button>
          )}
        </h4>
      )}

      <Row>
        <Col md={2}></Col>
        <Col md={8}>
          <div style={{ marginTop: '1em' }}>
            {updateAccess && (
              <Button
                style={{
                  marginBottom: '1em',
                  float: 'right',
                  marginLeft: '1em',
                }}
                onClick={() => setShowAddNew(true)}
              >
                Add New Rate
              </Button>
            )}
            {updateAccess && (
              <Button
                style={{ marginBottom: '1em', float: 'right' }}
                onClick={() => setShowAdd(true)}
              >
                Add to Collection
              </Button>
            )}
            <EnergyTracerTable
              data={tableData}
              columns={tableColumns}
              keyField={'id'}
            />
          </div>
        </Col>
      </Row>

      <ViewRateModal
        show={showView}
        onHide={() => handleHideView()}
        rows={viewRow ? [viewRow] : []}
      />
      <AddRateCollectionModal
        show={showAdd}
        onHide={() => setShowAdd(false)}
        collection={collection}
        organization_id={organizationId}
        rates={rates}
        updateList={updateList}
        showToast={showToast}
      />
      <AddOrganizationRateModal
        show={showAddNew}
        onHide={() => {
          setShowAddNew(false);
        }}
        showToast={showToast}
        organizationId={organizationId}
        addToList={addToList}
        updateList={updateList}
        rateCollections={[collection]}
        defaultCollection={collection}
      />
      <EditCustomRateCollectionModal
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
        }}
        showToast={showToast}
        organization_id={organizationId}
        collection={collection}
        updateList={updateList}
      />

      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
}
