import React from 'react';

const Footer = () => {
  return (
    <div className='et-footer d-flex fixed-bottom bg-primary text-white px-3'>
      <div
        className=' d-flex justify-content-between align-items-center w-100 m-auto '
        style={{ maxWidth: '1300px' }}
      >
        <small>
          EnergyTracer, developed by{' '}
          <a
            data-id='foot_orch'
            href='https://www.veregy.com/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white'
          >
            <span className='underline'>Veregy</span>
          </a>
        </small>
        <small
          data-id='foot_help'
          style={{ cursor: 'pointer' }}
          className='text-white underline'
          onClick={() => {
            window.open(
              'https://support.veregy.com/conversation/new',
              '_blank'
            );
          }}
        >
          Contact us
        </small>
      </div>
    </div>
  );
};

export default Footer;
