import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import EnergyTracerTable from '../../tables';
import consumptionUnits from '../../../helpers/consumptionUnits';

const styles = {
  column: {
    padding: '22px 15px 15px 15px',
    textAlign: 'center',
  },
  headerStyle: {
    textAlign: 'center',
  },
};

export default function BillDiscrepancyBillsModal(props) {
  const { show, onHide, bills } = props;

  const [hasProvider, setHasProvider] = useState(false);
  const [localUtility, setLocalUtility] = useState('production');

  const accountColumns = [
    {
      dataField: 'account_number',
      text: 'Account #',
      style: styles.column,
      headerStyle: styles.headerStyle,
      formatter: (cell, row) => (
        <div>
          <Link
            to={`/resources`}
            target='_blank'
            style={{
              display: 'inline-block',
              padding: '0',
            }}
          >
            {row.account_number}
          </Link>
        </div>
      ),
    },
  ];

  const basicColumns = [
    {
      dataField: 'start_date',
      text: 'Start Date',
      style: styles.column,
      headerStyle: styles.headerStyle,

      formatter: (cell) => <>{dayjs.utc(cell).format('YYYY-MM-DD')}</>,
    },
    {
      dataField: 'read_date',
      text: 'Read Date',
      style: styles.column,
      headerStyle: styles.headerStyle,

      formatter: (cell) => <>{dayjs.utc(cell).format('YYYY-MM-DD')}</>,
    },
    {
      dataField: 'total_consumption',
      text: 'Total Consumption (' + consumptionUnits[localUtility][0] + ')',
      style: styles.column,
      headerStyle: styles.headerStyle,

      formatter: (cell) => (
        <>
          {cell.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}
        </>
      ),
    },
    {
      dataField: 'total_cost',
      text: 'Total Cost',
      style: styles.column,
      headerStyle: styles.headerStyle,

      formatter: (cell) => (
        <>
          {'$' +
            cell.toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
        </>
      ),
    },
    {
      dataField: 'rate',
      text: 'Blended Rate ($/' + consumptionUnits[localUtility][0] + ')',
      style: styles.column,
      headerStyle: styles.headerStyle,

      formatter: (cell) => (
        <>
          {cell.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}
        </>
      ),
    },
  ];

  const providerColumns = [
    {
      dataField: 'provider_meter_name',
      text: 'Sub Account #',
      style: styles.column,
      headerStyle: styles.headerStyle,

      formatter: (cell) => <>{cell ? cell : 'Manual'}</>,
    },
  ];

  const tableColumns = [].concat(accountColumns, basicColumns);
  const tableColumnsProvider = [].concat(
    accountColumns,
    providerColumns,
    basicColumns
  );

  useEffect(() => {
    let has_provider = false;
    let local_utility = 'production';
    bills.forEach((bill) => {
      local_utility = bill.bill_type;
      if (bill.provider_meter_id && bill.provider_meter_id !== 'none') {
        has_provider = true;
      }
    });
    setHasProvider(has_provider);
    setLocalUtility(local_utility);
  }, [bills]);

  return (
    <Modal show={show} onHide={onHide} size='xl'>
      <Modal.Header closeButton> Associated Bills </Modal.Header>
      <Modal.Body>
        <EnergyTracerTable
          data={bills}
          columns={hasProvider ? tableColumnsProvider : tableColumns}
          keyField={'id'}
        />
      </Modal.Body>
    </Modal>
  );
}
