import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { UtilityTypesLookup } from '../ReportHelpers';
import ReportMonthSelect from '../../../components/reports/ReportMonthSelect';
import ReportCheckBoxPicker from '../../../components/reports/ReportCheckboxPicker';
import GHGReportUnitPicker from '../../../components/reports/GHGReportUnitPicker';

export default function ExcelReport(props) {
  const { organizationData, updatePayload, activeGHGLicense } = props;

  return (
    <>
      <Row>
        <ReportMonthSelect
          label='Start Month'
          default_date={organizationData['default_start_date']}
          min_date={organizationData['min_date']}
          max_date={organizationData['max_date']}
          payload_key={'start'}
          updatePayload={updatePayload}
          showPicker={true}
        />
        <ReportMonthSelect
          label='End Month'
          default_date={organizationData['default_end_date']}
          min_date={organizationData['min_date']}
          max_date={organizationData['max_date']}
          payload_key={'end'}
          updatePayload={updatePayload}
          showPicker={true}
        />
      </Row>
      <Row>
        <Col>
          <ReportCheckBoxPicker
            label={'Utility Types'}
            checkboxOptions={organizationData['utility_types']}
            checkboxLookups={UtilityTypesLookup}
            defaults={organizationData['utility_types']}
            requireds={[]}
            payload_key={'utility_types'}
            updatePayload={updatePayload}
          />
        </Col>
      </Row>
      {activeGHGLicense && (
        <GHGReportUnitPicker
          payload_key={'ghg_unit'}
          updatePayload={updatePayload}
        />
      )}
    </>
  );
}
