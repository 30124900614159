import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';

import dayjs from 'dayjs';

const defaultColor = '#212529';

function LicenseCell(props) {
  const { status, expiration } = props;

  const [fontColor, setFontColor] = useState(defaultColor);

  useEffect(() => {
    if (dayjs.utc(expiration) < dayjs.utc()) {
      setFontColor('red');
    } else {
      setFontColor(defaultColor);
    }
  }, [expiration]);

  return (
    <Col>
      <Col
        style={{
          color: fontColor,
          padding: '0',
          width: '85px',
          textAlign: 'center',
        }}
      >
        {status.charAt(0).toUpperCase() +
          status.substr(1).toLowerCase() +
          ' ' +
          (expiration ? dayjs.utc(expiration).format('YYYY-MM-DD') : '')}
      </Col>
    </Col>
  );
}

export default LicenseCell;
